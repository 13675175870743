import RootStore from '../RootStore';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { ContextStoreStatus } from './types';
import {ContextSchema} from "../../assets/types/contextSchema.json";
import { getContextName, getGroupTypesFilter, getLocale } from '../../services/EnvService';

export class ContextStore {
  public status: ContextStoreStatus = 'uninitialized';
  public context: ContextSchema | null= null;
  public locale: string = getLocale();

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      status: observable,
      locale: observable,
      context: observable,
      program: computed,
      groupTypes: computed,
      options: computed,
      setStatus: action,
      setContext: action,
      initialize: action,
    });
  }

  getContext(): ContextSchema {
    if (!this.context) {

      throw new Error('Context not found. Is the ContextStore initialized?');
    }

    return this.context;
  }

  get program(): string {

    return this.getContext().program;
  }

  get groupTypes(): string[] | null {

    return this.getContext().groupTypes ?? null;
  }

  getGroupTypesSet(): Set<string> | null {
    const groupTypes = this.groupTypes;

    return groupTypes === null ? null : new Set<string>(groupTypes);
  }

  get options(): string {

    return this.getContext().options;
  }

  setStatus = (status: ContextStoreStatus) => {
    this.status = status;
  }

  setContext(data: ContextSchema) {
    this.context = data;
  }

  setLocale(locale: string) {
    this.locale = locale;
  }

  async initialize(): Promise<void> {
    if(this.status !== 'uninitialized') {

      throw new Error('Context store is initialized yet.');
    }
    this.setStatus('initializing');
    const resp = await this.rootStore.requestStore.getContext(getContextName());
    if(resp.status === 'ok') {
      this.setStatus('initialized');
      let ctx = resp.payload;
      const groupTypes = getGroupTypesFilter();
      if(groupTypes !== null) {
        ctx = {...ctx, groupTypes}
      }
      this.setContext(ctx);
      console.info('Context store initialized.', toJS(this.context));
    }
    else {
      console.error('Could not load context.');
      this.setStatus('failed');
    }
  }
}
