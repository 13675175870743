import React from 'react';
import Icon from '../../Icon/Icon';
import IconButton from '../IconButton';
import { useLocalization } from '../../../stores/LocalizationStore/LocalizationProvider';

interface IProps {
  [index: string]: any,
}

const CloseButton: React.FC<IProps> = ({ ...props }) => {

  const t = useLocalization();

  return (
    <IconButton title={t('close')} {...props}>
      <Icon size={'large'} type={'close'} />
    </IconButton>
  );
};

export default CloseButton;
