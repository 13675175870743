import RootStore from '../RootStore';
import { action, computed, makeObservable, observable } from 'mobx';
import { Configuration, DomainStoreStatus } from './types';
import { Value } from '../../components/SelectInput/types';
import { LocalizationService, OCombinationGroup, ODomainData } from '../../services/DomainDataService';
import { ContextSchema } from '../../assets/types/contextSchema.json';
import { Combination } from '../../assets/types/programSchema.json';

const IMAGES_PATH = './images';

export class DomainStore {
  private localizationSrv!: LocalizationService;
  public status: DomainStoreStatus = 'uninitialized';
  public configuration: Configuration | null = null;
  public domainData: ODomainData | null = null;

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      status: observable,
      configuration: observable,
      domainData: observable,
      currentGroup: computed,
      uiGroups: computed,
      uiCombinations: computed,
      setStatus: action,
      setDomainData: action,
      setGroupKey: action,
      setCombinationKey: action,
      setConfiguration: action,
      initialize: action
    });
  }

  getConfiguration(): Configuration {
    if (!this.configuration) {

      throw new Error('Configuration not set. Is the DomainStore initialized?');
    }

    return this.configuration;
  }

  getCurrentCombination(): Combination {
    const configuration = this.getConfiguration();

    const combination = this
      .getDomainData()
      .findCombinationByKey(configuration.groupKey, configuration.combinationKey);
    if (!combination) {

      throw new Error(`Invalid configuration. Combination not found for configuration (${JSON.stringify(configuration)})`);
    }

    return combination;
  }

  getDomainData(): ODomainData {
    if (!this.domainData) {

      throw new Error('Domain data not found. Is the DomainStore initialized?');
    }

    return this.domainData;
  }

  get currentGroup(): OCombinationGroup {
    const { groupKey } = this.getConfiguration();
    const group = this.getDomainData().findOCombinationGroupByKey(groupKey);
    if (!group) {

      throw new Error(`Combination group not found for key '${groupKey}'.`);
    }

    return group;
  }

  get uiGroups(): Value[] {
    return this.getDomainData()
      .sortedCombinationGroups
      .map((item) => ({
        id: item.functionKey,
        iconUrl: IMAGES_PATH + '/' + item.groupIcon.value,
        label: this.localizationSrv.getGroupName(item.groupName)
      }));
  }

  get uiCombinations(): Value[] {

    return this.currentGroup.sortedCombinations.map((item) => ({
      id: item.coloredFunctionKey,
      iconUrl: IMAGES_PATH + '/' + item.materialImage.value,
      label: this.localizationSrv.getMaterialName(item.materialName)
    }));
  }

  setStatus = (status: DomainStoreStatus) => {
    this.status = status;
  };

  setDomainData(data: ODomainData) {
    this.domainData = data;
  }

  setInitialGroupKey = async (groupKey: string): Promise<void> => {
    const group = this.getDomainData().findOCombinationGroupByKey(groupKey);
    if (group === null) {

      throw new Error(`Invalid configuration. Combination group with key '${groupKey}' is not found.`);
    }
    const combinationKey = group.getFirstSortedCombination().coloredFunctionKey;

    await this.setConfiguration({
      groupKey,
      combinationKey
    });
  };

  setGroupKey = async (groupKey: string): Promise<void> => {
    const oldColor = this.getCurrentCombination().combinationColor;
    const group = this.getDomainData().findOCombinationGroupByKey(groupKey);
    if (group === null) {

      throw new Error(`Invalid configuration. Combination group with key '${groupKey}' is not found.`);
    }

    const combination = group.findCombinationByColor(oldColor);
    const combinationKey = null === combination
      ? group.getFirstSortedCombination().coloredFunctionKey
      : combination.coloredFunctionKey
    ;

    await this.setConfiguration({
      groupKey,
      combinationKey
    });
  };

  setCombinationKey = async (combinationKey: string): Promise<void> => {
    await this.setConfiguration({
      ...this.getConfiguration(),
      combinationKey
    });
  };

  async setConfiguration(config: Configuration): Promise<void> {
    this.configuration = { ...config };
    const group = this.getDomainData().findOCombinationGroupByKey(config.groupKey);
    if (group === null) {

      throw new Error(`Invalid configuration. Combination group with key '${config.groupKey}' is not found.`);
    }
    const combination = group.findCombinationByKey(config.combinationKey);
    if (combination === null) {

      throw new Error(
        `Invalid configuration. Combination with key '${config.combinationKey}' is not found in combination group with key '${config.groupKey}'.`
      );
    }
    await this.rootStore.viewerStore.setAssignments(combination.assetAssignments);
  }

  async initialize(context: ContextSchema, locale: string): Promise<void> {
    if (this.status !== 'uninitialized') {

      throw new Error('Domain store is initialized yet.');
    }
    this.localizationSrv = new LocalizationService(locale);
    this.setStatus('initializing');
    const resp = await this
      .rootStore
      .requestStore
      .getDomainData(context.program);
    if (resp.status === 'ok') {
      this.setStatus('initialized');
      this.setDomainData(new ODomainData(resp.payload, context.groupTypes ?? null));

      // use first group and combination as initial configuration
      const firstGroup = this.getDomainData().getFirstSortedGroup();
      await this.setInitialGroupKey(firstGroup.data.functionKey);

      console.info('Domain store initialized.');
    } else {
      this.setStatus('failed');
      console.error('Could not load domain data.');
    }
  }
}
