import React from 'react';
import FadeModal from '../FadeModal';

export const FADE_TIME = 350;
export const OPACITY = 50;

export interface IProps {
  hidden?: boolean,
  children: React.ReactNode,
}

const PopupModal: React.FC<IProps> = ({ children, ...props }) => {

  return (
    <FadeModal
      {...props}
      fadeTime={FADE_TIME}
      opacity={OPACITY}
    >
      {children}
    </FadeModal>
  );
};

export default PopupModal;
