import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/RootStoreProvider';
import Error from "../../components/Error";

interface IProps {
  children: React.ReactNode,
}

const AppInit: React.FC<IProps> = observer(({ children }) => {
  const {domainStore, contextStore, localizationStore} = useRootStore();
  if(domainStore.status === 'failed' || contextStore.status === 'failed' || localizationStore.status === 'failed') {

    return <Error/>
  }
  if(domainStore.status !== 'initialized' || contextStore.status !== 'initialized' || localizationStore.status !== 'initialized') {

    return null;
  }

  return (
    <>
      {children}
    </>
  );
});

export default AppInit;
