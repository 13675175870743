import { action, computed, makeObservable, observable } from 'mobx';
import { AssetAssignment } from '../../assets/types/programSchema.json';
import { createViewer, Viewer } from '../../services/ViewerService';
import { IDimensions2D } from 'webgl-helpers';
import { ArCapabilityHandler, DebugViewerStats, DebugViewerStatsHandler, ViewerStoreStatus } from './types';
import RootStore from '../RootStore';
import { DEBUG } from '../../utils/debug';
import { stripTrailingSlash } from '../../utils/string';
import { FADE_TIME } from '../../components/modals/CurtainModal';

export class ViewerStore {
  public status: ViewerStoreStatus = 'uninitialized';
  public assigning: boolean = false;
  private viewer: Viewer | null = null;
  private parent: HTMLElement | null = null;
  private size: IDimensions2D | null = null;
  private assignments: AssetAssignment[] | null = null;
  private shouldDestroy: boolean = false;
  public debugViewerStats: DebugViewerStats | null = null;
  public clickCount: number = 0;
  public arEnabled: boolean = true;

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      status: observable,
      assigning: observable,
      debugViewerStats: observable,
      clickCount: observable,
      arEnabled: observable,
      debugAdminCombinationUrl: computed,
      execute: action,
      setStatus: action,
      setDebugViewerStats: action,
      setArEnabled: action,
      incClickCount: action
    });
  }

  getViewer() {
    if (this.viewer === null) {

      throw new Error('Viewer is not initialized.');
    }

    return this.viewer;
  }

  get debugAdminCombinationUrl(): string | null {
    if (!DEBUG) {

      return null;
    }
    const adminUrl = stripTrailingSlash(process.env.REACT_APP_DEBUG_ADMIN_URL ?? '');
    if (adminUrl.length === 0) {

      return null;
    }
    const config = this.rootStore.domainStore.getConfiguration();
    const coloredFunctionKey = this.rootStore
      .domainStore
      .getDomainData()
      .findCombinationByKey(config.groupKey, config.combinationKey)
      ?.coloredFunctionKey;
    if (coloredFunctionKey === undefined) {

      return null;
    }

    return `${adminUrl}#/combinations/${encodeURIComponent('/api/combinations/')}${coloredFunctionKey}/show`;
  }

  public setDebugViewerStats: DebugViewerStatsHandler = (stats): void => {
    if (stats.textures !== this.debugViewerStats?.textures && stats.geometries !== this.debugViewerStats?.geometries) {
      this.debugViewerStats = stats;
    }
  };

  public setArEnabled: ArCapabilityHandler = (value): void => {
    if (this.arEnabled !== value) {
      this.arEnabled = value;
    }
  }

  setStatus(status: ViewerStoreStatus): void {
    this.status = status;
  }

  setAssigning(assigning: boolean): void {
    this.assigning = assigning;
  }

  async setParent(parent: HTMLElement) {
    if (this.shouldDestroy) {

      return;
    }
    if (this.parent !== null && this.status !== 'uninitialized') {

      throw new Error('Parent is set yet!');
    }
    this.parent = parent;
    await this.execute('parent');
  }

  async setSize(width: number, height: number) {
    this.size = { width, height };
    await this.execute('size');
  }

  setAssignments = async (assignments: AssetAssignment[]) => {
    this.setAssigning(true);
    setTimeout(async () => {
      this.assignments = assignments;
      await this.execute('assignments');
      this.setAssigning(false);
    }, FADE_TIME);
  };

  async execute(type: 'parent' | 'size' | 'assignments') {
    if (this.status === 'uninitialized') {
      if (this.parent !== null && this.size !== null) {
        this.setStatus('initializing');
        console.info('Viewer initializing.');
        this.viewer = await createViewer(this.parent, this.size, this.rootStore.contextStore.getContext(), this.rootStore.requestStore, this.setArEnabled);
        this.viewer.setStatsCallback(this.setDebugViewerStats);
        this.setStatus('initialized');
        console.info('Viewer initialized.');
        if (this.shouldDestroy) {
          this.destroy();
        } else if (this.assignments !== null) {
          await this.getViewer().loadConfiguration(this.assignments);
          this.getViewer().start('ifNeeded');
          this.setStatus('started');
          console.info('Viewer started.');
        }
      }
    } else if (this.status === 'initialized') {
      if (this.assignments !== null) {
        const viewer = this.getViewer();
        if (this.size === null) {

          throw new Error('Size is not set.');
        }
        viewer.resize(this.size);
        await viewer.loadConfiguration(this.assignments);
        this.getViewer().start();
        this.setStatus('started');
        console.info('Viewer started.');
      }
    } else if (this.status === 'started') {
      if (this.assignments === null) {

        throw new Error('There are no assignments.');
      }
      if (this.size === null) {

        throw new Error('Size is not set.');
      }
      if (type === 'assignments') {
        this.getViewer().resetScene();
        await this.getViewer().loadConfiguration(this.assignments);
        console.info('New viewer configuration loaded.');
      } else if (type === 'size') {
        this.getViewer().resize(this.size);
        console.info('Viewer resized.');
      }
    }
  }

  destroy() {
    console.info(`Destroy viewer for status ${this.status}`);
    if (this.status === 'uninitialized') {

      return;
    }
    if (this.status === 'initializing') {
      this.shouldDestroy = true;

      return;
    }
    this.getViewer().destroy();
    this.viewer = null;
    this.parent = null;
    this.size = null;
    this.assignments = null;
    this.shouldDestroy = false;
    this.setStatus('uninitialized');
  }

  incClickCount = (): void => {
    this.clickCount++;
  }
}
