export const getGroupTypesFilter = (): null | string[] => {
    let value: string | null = new URLSearchParams(window.location.search).get('gt');

    if (null === value) {

        return null;
    }

    return value.split(',').map(f => f.trim());
}
