import React from 'react';
import Overlay from '../../Overlay';
import Modal from '../Modal';
import { useFading } from '../../../hooks/useFading';
import { Opacity } from '../../Overlay/types';

export interface IProps {
  hidden?: boolean,
  fadeTime: number,
  opacity: Opacity,
  children: React.ReactNode,
}

const FadeModal: React.FC<IProps> = ({ hidden = false, fadeTime, opacity, children }) => {
  const fadeProps = useFading(!hidden, fadeTime);
  if (!fadeProps.inDom) {

    return null;
  }

  return (
    <Modal>
      <Overlay
        hidden={hidden}
        fadeTime={fadeTime}
        opacity={opacity}
      >
        {children}
      </Overlay>
    </Modal>
  );
};

export default FadeModal;
