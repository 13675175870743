import { AssetAssignment, FrameSlot, Transformation } from '../../../assets/types/programSchema.json';
import * as THREE from 'three';
import { ScaleFactor } from './ScaleFactor';
import { Frame, IJungGeometryData } from '../types';
import { MeshUtils } from 'webgl-helpers';

export class ConfigTransformation {

    constructor(private scaleFactor:ScaleFactor) {}

    private frameTransformation?: Transformation
    private frameSlots: FrameSlot[] = [];

    public setBackWallTransformation(geo: THREE.Object3D, frameGeo: THREE.Object3D): THREE.Object3D[]  {
        const utils = new MeshUtils();
        const helper = utils.buildAxesHelper('wallPosition', 1, 1);
        const frameHelper = utils.buildAxesHelper('framePosition', 1, 1);
        const frameBBData = utils.createBoundingBox(frameGeo);
        const frameBBBox = utils.buildBoundingBox(frameGeo);
        // @ts-ignore
        frameBBBox.material.wireframe = true;
        const z = frameBBData.center.z;
        geo.rotation.copy(frameGeo.rotation);
        geo.position.set(frameBBData.center.x, frameBBData.center.y, z - frameBBData.size.z / 2);
        frameBBBox.position.copy(frameGeo.position);
        helper.position.copy(frameBBBox.position);
        frameHelper.position.copy(frameGeo.position);

        return [helper, frameHelper, frameBBBox];
    }

    public setLSZeroBackWallPosition(geo: THREE.Object3D, frameGeo: THREE.Object3D): THREE.Object3D[]  {
        const utils = new MeshUtils();
        const helper = utils.buildAxesHelper('wallPosition', 1, 1);
        const frameHelper = utils.buildAxesHelper('framePosition', 1, 1);
        const frameBBData = utils.createBoundingBox(frameGeo);
        const frameBBBox = utils.buildBoundingBox(frameGeo);
        // @ts-ignore
        frameBBBox.material.wireframe = true;
        const z = frameBBData.center.z;
        geo.rotation.copy(frameGeo.rotation);
        geo.position.set(frameBBData.center.x, frameBBData.center.y, (z + frameBBData.size.z / 2) - 0.001);
        frameBBBox.position.copy(frameGeo.position);
        helper.position.copy(frameBBBox.position);
        frameHelper.position.copy(frameGeo.position);

        return [helper, frameHelper, frameBBBox];
    }

    public setGeometryTransformation(geo: THREE.Object3D, assignment: AssetAssignment, geoData: IJungGeometryData): Transformation|undefined {
        if (Frame === geoData.type) {
            return this.setFrameTransformation(geo, assignment)
        }
        return this.setModuleTransformation(geo, assignment);
    }

    public setChildTransformation(geo: THREE.Object3D, parent: THREE.Object3D, assignment: AssetAssignment): Transformation {
        const transformation = {...assignment.data.transformation}
        this.applyTransformationScaleFactor(transformation);
        transformation.positionHorizontal += parent.position.x;
        transformation.positionVertical += parent.position.y;
        transformation.positionDepth += parent.position.z;
        return this.applyTransformation(geo, transformation);
    }

    private setModuleTransformation(geo: THREE.Object3D, assignment: AssetAssignment): Transformation|undefined {
        if (!this.frameTransformation || 0 === this.frameSlots.length || !this.frameTransformation.referenceDepth) {
            console.error('Could not apply module position, missing frame transformation. Make sure to add frame first!');
            return;
        }
        if (undefined === assignment.data.positionIndex || null === assignment.data.positionIndex) {
            console.error('Could not apply module position, missing position index!');
            return;
        }

        const slotPosition = {...this.frameSlots[assignment.data.positionIndex]};
        const modulePosition = {...assignment.data.transformation};

        const horizontalPos = this.frameTransformation.positionHorizontal + slotPosition.horizontal + modulePosition.positionHorizontal;
        const verticalPos   = this.frameTransformation.positionVertical + slotPosition.vertical + modulePosition.positionVertical;
        const depthPos      = this.frameTransformation.positionDepth + this.frameTransformation.referenceDepth + modulePosition.positionDepth;

        modulePosition.positionHorizontal = this.applyScaleFactor(horizontalPos);
        modulePosition.positionVertical = this.applyScaleFactor(verticalPos);
        modulePosition.positionDepth = this.applyScaleFactor(depthPos);

        return this.applyTransformation(geo, modulePosition);
    }

    private setFrameTransformation(geo: THREE.Object3D, assignment: AssetAssignment): Transformation {
        if (this.frameTransformation) {
            console.warn('Overwriting current frame transformation!');
        }
        this.frameTransformation = {...assignment.data.transformation};
        this.frameTransformation.positionDepth = 0;
        this.frameSlots = {...assignment.data.frameSlots};
        return this.applyTransformation(geo, this.frameTransformation);
    }

    private applyTransformation(geo: THREE.Object3D, transformation: Transformation): Transformation {
        geo.position.set(
            transformation.positionHorizontal,
            transformation.positionVertical,
            transformation.positionDepth
        );
        geo.rotation.set(
            THREE.MathUtils.degToRad(transformation.rotationX),
            THREE.MathUtils.degToRad(transformation.rotationY),
            THREE.MathUtils.degToRad(transformation.rotationZ),
        );

        return transformation;
    }

    private applyTransformationScaleFactor(transformation: Transformation) {

        transformation.positionDepth = this.applyScaleFactor(transformation.positionDepth)
        transformation.positionHorizontal = this.applyScaleFactor(transformation.positionHorizontal)
        transformation.positionVertical = this.applyScaleFactor(transformation.positionVertical)
    }

    private applyScaleFactor(value: number): number {
        return this.scaleFactor.applyThreeScaleFactor(value);
    }
}

