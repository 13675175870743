import * as React from 'react';
import styles from './Overlay.module.scss';
import classNames from 'classnames';
import { getCSSVariable } from '../../utils/css';
import { Opacity } from './types';

interface IProps {
  hidden?: boolean,
  opacity?: Opacity | undefined,
  fadeTime?: number | undefined,
  children?: React.ReactNode,

  [index: string]: any;
}

const Overlay: React.FC<IProps> = ({
                                     hidden = false,
                                     opacity = 0.5,
                                     fadeTime= 350,
                                     children,
                                     ...props
                                   }) => {
  const classes = classNames(
    styles.overlay,
    hidden ? styles.hidden : styles.visible
  );

  const elemStyles = {
    animationDuration: fadeTime + 'ms',
    backgroundColor: getCSSVariable(`--color-white--${opacity}`),
  };

  return (
    <div className={classes} {...props} style={elemStyles}>
      {children}
    </div>
  );
};

export default Overlay;
