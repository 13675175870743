import RootStore from '../RootStore';
import {
  ArPlacement, ArScale,
  isArKeySchema,
  isContextSchema,
  isDomainDataSchema, isMaterialOptionSchema
} from './types';
import { ContextSchema } from '../../assets/types/contextSchema.json';
import { ProgramSchema } from '../../assets/types/programSchema.json';
import {
  AppErrorResponse,
  AppOkResponse,
  AppUnreachableResponse
} from './appResponses';
import { makeObservable } from 'mobx';
import yaml from 'js-yaml';
import JSZip from 'jszip';
import { LocaleSchema } from '../LocalizationStore/types';
import { ArKeySchema } from '../../assets/types/arKeySchema.json';
import { BlenderCreation } from '../../assets/types/blenderCreation.json';
import {MaterialOptionsSchema} from "../../assets/types/materialOptionsSchema.json";
import { stripTrailingSlash } from '../../utils/string';

const LOCAL_HOST = '.';
const AR_SERVICE_HOST = stripTrailingSlash(process.env.REACT_APP_AR_SERVICE_HOST ?? '');
if (AR_SERVICE_HOST.length === 0) {

  throw new Error('Host of the ar-service is not defined. Please check env variable REACT_APP_AR_SERVICE_HOST.');
}

const AR_SERVICE_API_KEY = process.env.REACT_APP_AR_SERVICE_API_KEY ?? '';
if (AR_SERVICE_API_KEY.length === 0) {

  throw new Error('Api key for the ar-service is not defined. Please check env variable REACT_APP_AR_SERVICE_API_KEY.');
}

const AR_SERVICE_PROJECT = process.env.REACT_APP_AR_SERVICE_PROJECT ?? '';
if (AR_SERVICE_PROJECT.length === 0) {

  throw new Error('Project for the ar-service is not defined. Please check env variable REACT_APP_AR_SERVICE_PROJECT.');
}


const DISABLE_ZIPPED_DOMAIN_DATA = process.env.REACT_APP_DISABLE_ZIPPED_DOMAIN_DATA === 'on';
if (DISABLE_ZIPPED_DOMAIN_DATA) {
  console.warn(
    'Domain data is loaded as plain text. You can load zipped data by converting the file ' +
    '\'public/assets/domain/[PROGRAM].json\' to \'public/assets/domain/[PROGRAM].json.zip\' and setting the env  ' +
    'variable \'REACT_APP_DISABLE_ZIPPED_DOMAIN_DATA\' to \'off\'.'
  );
}

const VALIDATE_LOCAL_REQUEST_RESPONSES = process.env.REACT_APP_VALIDATE_LOCAL_REQUEST_RESPONSES === 'on';
if (VALIDATE_LOCAL_REQUEST_RESPONSES) {
  console.warn(
    'The response of local input files (i.e. \'public/assets/domain/*\' and ' +
    '\'public/assets/contexts/*) is validated. This could be a performance issue in live environments. To disable ' +
    'it set the env variable \'REACT_APP_VALIDATE_LOCAL_REQUEST_RESPONSES\' to \'off\'.'
  );
}

export class RequestStore {
  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {});
  }

  async getDomainData(program: string): Promise<AppOkResponse<ProgramSchema> | AppErrorResponse | AppUnreachableResponse> {
    const accept = DISABLE_ZIPPED_DOMAIN_DATA ? 'application/json' : 'application/zip';
    const path = `assets/domain/${program}.json${DISABLE_ZIPPED_DOMAIN_DATA ? '' : '.zip'}`;
    let response: Response;
    try {
      response = await this.fetch(`${LOCAL_HOST}/${path}`, {
        headers: {
          accept
        }
      });
    } catch (e) {

      return new AppUnreachableResponse();
    }
    if (!response.ok) {
      console.error(`Response has status ${response.status}`);

      return new AppErrorResponse(response);
    }
    let payload: unknown;
    if (DISABLE_ZIPPED_DOMAIN_DATA) {
      try {
        payload = await response.json();
      } catch (e) {
        console.error('Invalid json.', e);

        return new AppErrorResponse(response);
      }
    } else {
      let text: string | undefined;
      try {
        const blob = response.blob();
        const zip = await JSZip.loadAsync(blob);
        text = await zip.file(`${program}.json`)?.async('string');
      } catch (e) {
        console.error('Invalid zip.', e);

        return new AppErrorResponse(response);
      }
      if (text === undefined) {
        console.error('File has no content.');

        return new AppErrorResponse(response);
      }
      try {
        payload = JSON.parse(text);
      } catch (e) {
        console.error('Invalid json.', e);

        return new AppErrorResponse(response);
      }
    }
    if (VALIDATE_LOCAL_REQUEST_RESPONSES) {
      console.info('Validate domain data.');
      if (!isDomainDataSchema(payload)) {

        return new AppErrorResponse(response);
      }

      return new AppOkResponse(payload, response);
    }

    return new AppOkResponse(payload as ProgramSchema, response);
  }

  async getMaterialOptions(path: string): Promise<AppOkResponse<MaterialOptionsSchema> | AppErrorResponse | AppUnreachableResponse> {
    let response: Response;
    try {
      response = await this.fetch(`${LOCAL_HOST}/${path}`, {
        headers: {
          accept: 'text/json'
        }
      });
    } catch (e) {

      return new AppUnreachableResponse();
    }
    if (!response.ok) {
      console.error(`Response has status ${response.status}`);

      return new AppErrorResponse(response);
    }
    let payload: unknown;
    try {
      const payloadStr = await response.text();
      payload = JSON.parse(payloadStr);
    } catch (e) {
      console.error('Invalid json.');

      return new AppErrorResponse(response);
    }
    if (VALIDATE_LOCAL_REQUEST_RESPONSES) {
      console.info('Validate material options data.');
      if (!isMaterialOptionSchema(payload)) {

        return new AppErrorResponse(response);
      }

      return new AppOkResponse(payload, response);
    }

    return new AppOkResponse(payload as MaterialOptionsSchema, response);
  }

  async getContext(name: string): Promise<AppOkResponse<ContextSchema> | AppErrorResponse | AppUnreachableResponse> {
    let response: Response;
    try {
      response = await this.fetch(`${LOCAL_HOST}/assets/contexts/${name}.yml`, {
        headers: {
          accept: 'text/yaml'
        }
      });
    } catch (e) {

      return new AppUnreachableResponse();
    }
    if (!response.ok) {
      console.error(`Response has status ${response.status}`);

      return new AppErrorResponse(response);
    }
    let payload: unknown;
    try {
      const payloadStr = await response.text();
      payload = yaml.load(payloadStr);
    } catch (e) {
      console.error('Invalid yaml.');

      return new AppErrorResponse(response);
    }
    if (VALIDATE_LOCAL_REQUEST_RESPONSES) {
      console.info('Validate context data.');
      if (!isContextSchema(payload)) {

        return new AppErrorResponse(response);
      }

      return new AppOkResponse(payload, response);
    }

    return new AppOkResponse(payload as ContextSchema, response);
  }

  async getLocale(name: string): Promise<AppOkResponse<LocaleSchema> | AppErrorResponse | AppUnreachableResponse> {
    let response: Response;
    try {
      response = await this.fetch(`${LOCAL_HOST}/assets/locales/${name}.json`, {
        headers: {
          accept: 'application/json'
        }
      });
    } catch (e) {

      return new AppUnreachableResponse();
    }
    if (!response.ok) {
      console.error(`Response has status ${response.status}`);

      return new AppErrorResponse(response);
    }
    let payload: LocaleSchema;
    try {
      payload = await response.json();
    } catch (e) {
      console.error('Invalid json.');

      return new AppErrorResponse(response);
    }

    return new AppOkResponse(payload, response);
  }

  getArUrl(key: string, placement: ArPlacement = 'wall', scaleMode: ArScale = 'auto') {

    return `${AR_SERVICE_HOST}/model-viewer/${AR_SERVICE_PROJECT}/${key}?api_key=${AR_SERVICE_API_KEY}&ar_placement=${placement}&ar_scale=${scaleMode}&ar_immediately=on&min_camera_orbit=-70deg 40deg auto&max_camera_orbit=70deg 120deg auto`;
  }

  async getArKey(payload: BlenderCreation): Promise<AppOkResponse<ArKeySchema> | AppErrorResponse | AppUnreachableResponse> {
    let response: Response;
    try {
      response = await this.fetch(`${AR_SERVICE_HOST}/geometry/create/${AR_SERVICE_PROJECT}?api_key=${AR_SERVICE_API_KEY}`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          accept: 'application/json'
        },
        body: JSON.stringify(payload)
      });
    } catch (e) {

      return new AppUnreachableResponse();
    }
    if (!response.ok) {
      console.error(`Response has status ${response.status}`);

      return new AppErrorResponse(response);
    }
    let respPayload: unknown;
    try {
      respPayload = await response.json();
    } catch (e) {
      console.error('Invalid json.');

      return new AppErrorResponse(response);
    }
    if (!isArKeySchema(respPayload)) {

      return new AppErrorResponse(response);
    }

    return new AppOkResponse(respPayload, response);
  }

  async fetch(path: string, init?: RequestInit): Promise<Response> {
    let response: Response;
    try {
      response = await fetch(path, init);
    } catch (e: any) {
      console.error('Server is not available', e);

      throw e;
    }

    return response;
  }
}
