import { ArOptions } from '../../../assets/types/contextSchema.json';
import { LoadedGeometry } from '../types';

export class ARDetermination {
  constructor(private readonly arOptions: ArOptions|undefined) {
  }

  public arIsPossible(geometries: LoadedGeometry[]): boolean {
    for (const geo of geometries) {
      if (!this.geometryIsArCapable(geo)) {
        return false;
      }
    }
    return true;
  }

  private geometryIsArCapable(geometry: LoadedGeometry): boolean {
    if (!this.arOptions || !this.arOptions.disabledGeometries) {
      return true;
    }
    const name = geometry.geometry.geo?.name as string;

    return !this.arOptions.disabledGeometries.includes(name);
  }
}