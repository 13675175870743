import RootStore from '../RootStore';
import { action, makeObservable, observable } from 'mobx';
import { ArStoreStatus } from './types';

export class ArStore {
  public url: string | null = null;
  public status: ArStoreStatus = 'notGenerated';

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      status: observable,
      url: observable,
      generate: action,
      setStatus: action,
      setUrl: action
    });
  }

  async generate() {
    if(this.status === 'generating') {

      throw new Error('The generation of the url is running currently!')
    }
    this.setUrl(null);
    this.setStatus('generating');
    const json = await this.rootStore
      .viewerStore
      .getViewer()
      .createBlenderCreationJson();
    const resp = await this.rootStore
      .requestStore
      .getArKey(json);
    if (resp.status === 'ok') {
      this.setStatus('generated');
      this.setUrl(this.rootStore.requestStore.getArUrl(resp.payload.data.key));
    } else {
      this.setStatus('failed');
    }
  }

  setStatus = (status: ArStoreStatus) => {
    this.status = status;
  };

  setUrl = (url: string | null) => {
    this.url = url;
  };
}
