import React from 'react';
import styles from './DebugImages.module.scss';
import Modal from '../modals/Modal';

interface IProps {
  doneNum: number,
  totalNum: number,
}

const DebugImages: React.FC<IProps> = ({ doneNum, totalNum }) => {

  return (
    <Modal>
      <div className={styles.debugPopupWrapper}>
        <div className={styles.debugPopupContent}>

          <div className={styles.processWrapper}>
            <div className={styles.processBar} style={{
              width: `${doneNum / totalNum * 100}%`
            }}>
              <div className={styles.percentage}>{`${(doneNum / totalNum * 100).toFixed(0)}%`}</div>
            </div>
          </div>

          {doneNum} of {totalNum} images processed.
        </div>
      </div>
    </Modal>
  );
};

export default DebugImages;
