import { AssetName } from '../../assets/types/programSchema.json';

export class LocalizationService {
  constructor(public readonly locale: string) {
  }

  getGroupName(names: AssetName[] | { [key: string]: AssetName }): string {
    const namesArray = Array.isArray(names) ? names : Object.values(names);
    const translation = namesArray.find(n => n.localization.name === this.locale);
    if (translation === undefined) {
      console.error(`Group name not found for '${this.locale}'.`);

      return '';
    }

    return translation.value;
  }

  getMaterialName(names: AssetName[]  | { [key: string]: AssetName }): string {
    const namesArray = Array.isArray(names) ? names : Object.values(names);
    const translation = namesArray.find(n => n.localization.name === this.locale);
    if (translation === undefined) {
      console.error(`Material name not found for '${this.locale}'.`);

      return '';
    }

    return translation.value;
  }

}