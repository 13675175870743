import React, { useLayoutEffect, useRef } from 'react';
import { useRootStore } from '../../stores/RootStoreProvider';
import { getCSSVariable } from '../../utils/css';

const styles = {
  width: '100%',
  height: '100%',
  // zIndex: 0,
  overflow: 'hidden',
  background: getCSSVariable('--color-main-background')
} as React.CSSProperties;

const Viewer = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { viewerStore } = useRootStore();
  useLayoutEffect(() => {
    console.info('Create Viewer UI.');
    const parent = ref.current as HTMLDivElement;
    (async () => {
      await viewerStore.setParent(parent);
    })();
    const observer = new ResizeObserver(async () => {
      await viewerStore.setSize(parent.offsetWidth, parent.offsetHeight);
    });
    observer.observe(parent);

    return () => {
      console.info('Destroy Viewer UI.');
      observer.disconnect();
      viewerStore.destroy();
    };
  }, [viewerStore]);


  return (
    <div
      onMouseDown={viewerStore.incClickCount}
      onTouchStart={viewerStore.incClickCount}
      ref={ref}
      style={{ ...styles }}
    />
  );
};

export default Viewer;
