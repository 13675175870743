import React from 'react';
import { embedded } from '../../../utils/embedded';

interface IProps {
  embedded: boolean,
  children: React.ReactNode;
}

const createStyles = (embedded: boolean): React.CSSProperties => {

  const styleObj: React.CSSProperties = {
    width: '100svw',
    height: '100svh'
  };

  // for embedded app (iframe) min height is always 700px: Customer uses iframe-resizer plugin to manage the
  // iframes containing this app. The plugin expects a minimal height.
  // minHeight: embedded ? '700px' : 'max(min(700px, 100svh), 300px)'
  if(embedded) {
    styleObj.minWidth = '300px';
    styleObj.minHeight = '700px';
  }

  return styleObj
};


export const LayoutUI: React.FC<IProps> = ({ embedded, children }) => {

  return (
    <div style={createStyles(embedded)}>
      {children}
    </div>
  );
};

const Layout = ({children, ...props}: Omit<IProps, 'embedded'>) => (
  <LayoutUI
    {...props}
    embedded={embedded()}
  >
    {children}
  </LayoutUI>
);

  export default Layout;
