import {IFitViewFactor, IGeometryData, IMaterialOptions, IWebGlOptions} from "webgl-helpers";
import {AssetAssignment, Color, Transformation} from "../../assets/types/programSchema.json";
import {IGeometry} from "webgl-helpers/src/Interfaces";
import * as THREE from "three";
import {EmissiveMap} from "../../assets/types/materialOptionsSchema.json";
import {ContainerConfiguration} from "./scene/SceneContainer";

export const Module = 'TYPE_MODULE';
export const Frame  = 'TYPE_FRAME';
export const BackWall = 'TYPE_BACKWALL'
export type GeoType = typeof Module | typeof Frame | typeof BackWall;

export interface IJungSceneContent {
    frame: THREE.Object3D,
    modules: THREE.Object3D[]
    backWall?: THREE.Object3D
}

export interface IJungGeometryData extends IGeometryData<string>{
    assignment: AssetAssignment
}

export interface IJungGeometry extends IGeometry<string> {
    definition: IJungGeometryData
    geo?: THREE.Object3D
}

export interface IViewConfiguration extends IWebGlOptions {
    container: ContainerConfiguration
}


export interface IViewSettings extends IFitViewFactor {
    camera_x: number
    camera_y: number
    camera_z: number
    baseFactor: number
}

export interface IEmissiveMap extends EmissiveMap {
    texture: THREE.Texture
}

export interface IJungMaterialOptions extends IMaterialOptions<string> {
    emissive?: IEmissiveMap
    texture?: THREE.Texture
    aoPath?: string,
    emissivePath?: string
    texturePath?: string
    textureColor?: string
    color?: Color|null
    depthMask?: boolean
    textureRepeat?: THREE.Vector2
}

export type LoadedGeometry = {
    geometry: IJungGeometry
    materials: IJungMaterialOptions[]
    transformation?: Transformation|undefined
    child?: LoadedGeometry
}