import { Combination, CombinationGroup } from '../../assets/types/programSchema.json';

export class OCombinationGroup {
  constructor(public readonly data: CombinationGroup) {
  }

  get sortedCombinations(): Combination[] {
    const combinations = [...this.data.combinations];
    combinations.sort((a, b) => a.sortIndex - b.sortIndex);

    return combinations;
  }

  findCombinationByKey(key: string): Combination | null {
    const combination = this
      .data
      .combinations
      .find(g => g.coloredFunctionKey === key);

    return combination ?? null;
  }

  findCombinationByColor(color: string): Combination | null {

    return this
      .data
      .combinations
      .find(g => g.combinationColor === color) ?? null
  }

  getFirstSortedCombination(): Combination {
    const combinations = this.sortedCombinations;
    if (combinations.length < 1) {

      throw new Error(`Combination group '${this.data.groupName}' does not contain combinations.`);
    }

    return combinations[0];
  }
}
