import { makeObservable } from 'mobx';
import DomainStore from '../DomainStore';
import RequestStore from '../RequestStore';
import ContextStore from "../ContextStore";
import ViewerStore from '../ViewerStore';
import LocalizationStore from '../LocalizationStore';
import DebugImagesStore from '../DebugImagesStore';
import ArStore from '../ArStore';

export class RootStore {
  readonly domainStore: DomainStore;
  readonly contextStore: ContextStore;
  readonly requestStore: RequestStore;
  readonly viewerStore: ViewerStore;
  readonly localizationStore: LocalizationStore;
  readonly arStore: ArStore;
  readonly debugImagesStore: DebugImagesStore;


  constructor() {
    this.domainStore = new DomainStore(this);
    this.requestStore = new RequestStore(this);
    this.contextStore = new ContextStore(this);
    this.viewerStore = new ViewerStore(this);
    this.localizationStore = new LocalizationStore(this);
    this.arStore = new ArStore(this);
    this.debugImagesStore = new DebugImagesStore(this);

    this.initialize();

    makeObservable(this, {});
  }
  async initialize(): Promise<void> {
    await this.contextStore.initialize();
    if(this.contextStore.status === 'initialized') {
      const context = this.contextStore.context;
      if(context === null) {

        throw new Error('Context not found.');
      }
      const locale = this.contextStore.locale;
      await this.localizationStore.initialize(locale);
      await this.domainStore.initialize(context, locale);
    }
  }
}
