import React from 'react';
import styles from './Error.module.scss'

interface IProps {
}

const Error: React.FC<IProps> = () => {

    return (
        <div className={styles.wrapper}>
        <div className={styles.text}>
            Something went wrong.
        </div>
        </div>
    );
};

export default Error;
