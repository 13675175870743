import * as THREE from "three";

const typeJpeg = 'image/jpeg';
const typePng =  'image/png';

export class TextureLoader {
    public async getTextureFile(path: string): Promise<THREE.Texture | false> {
        try {
            const exists = await this.textureExists(path);
            if (exists) {
                const tex = await new THREE.TextureLoader().loadAsync(path);
                tex.wrapS = THREE.RepeatWrapping;
                tex.wrapT = THREE.RepeatWrapping;

                return tex;
            }

            return false;
        } catch (e: any) {
            console.error(`The file at ${path} cannot be accessed. Error: ${e.toString()}`);
            return false;
        }
    }

    public async textureExists(path: string): Promise<boolean> {
        try {
            const response = await fetch(path, { method: 'HEAD' });
            const responseType = response.headers.get('content-type');
            if (!response.ok) {
                console.warn(`No texture found: ${path}`);
                return false;
            }
            if (responseType !== typePng && responseType !== typeJpeg) {
                console.warn(`No texture found: ${path}`);
                return false;

            }
        } catch (e: any) {
            console.error(`The file at ${path} cannot be accessed. Error: ${e.toString()}`);
            return false;
        }
        return true;
    }
}