import React from 'react';
import styles from './SelectContainerLayout.module.scss';

interface IProps {
  children: React.ReactNode;

  [index: string]: any,
}

const SelectContainerLayout = React.forwardRef<HTMLDivElement, IProps>(({ children, ...props }, ref) => {

  return (
    <div className={styles.refWrapper} ref={ref}>
      <div {...props} className={styles.selectContainerLayout}>
        {children}
      </div>
    </div>
  );
});

export default SelectContainerLayout;
