import { IJungMaterialOptions, LoadedGeometry } from '../types';
import {
    AbsoluteVector,
    BlenderCreation,
    Geometry,
    GlbOptions,
    Material,
    Options,
    Scene,
    Texture,
    UvMapping
} from '../../../assets/types/blenderCreation.json';
import * as THREE from 'three';
import { getAssetHostUrl } from '../../../utils/url';
import { Transformation } from '../../../assets/types/programSchema.json';
import { depthMask } from '../material/MaterialManager';
import { NameSerializer } from './NameSerializer';

export class BlenderTransformer {

    constructor(private serializer: NameSerializer) {
    }

    public transformSceneToBlender(content: LoadedGeometry[]): BlenderCreation {
        return {
            options: this.getOptions(),
            scene: this.createScene(content)
        }
    }

    private createScene(content: LoadedGeometry[]): Scene {
        const lib = 'Dummy value - replaced by ar service'
        const geometryDir = 'Dummy value - replaced by ar service'
        const geometries = [];
        for (const geometry of content) {
            geometries.push(this.createGeometry(geometry));
            if (geometry.child) {
                geometries.push(this.createGeometry(geometry.child));
            }
        }

        return {
            materialLibrary: lib,
            geometryDirectory: geometryDir,
            geometries: geometries
        }
    }

    private createGeometry(geometry: LoadedGeometry): Geometry {
        const geo = geometry.geometry.geo as THREE.Object3D;
        const name = this.serializer.serializeGeometryName(geo.name);
        return {
            file: this.serializer.serializeGeometryFilePath(geometry.geometry.definition.filePath),
            materials: this.createMaterialList(geometry.materials),
            children: [{name: name, root: true}],
            position: this.createPosition(geo),
            rotation: this.createRotation(geometry.transformation)
        }
    }

    private createPosition(obj: THREE.Object3D): AbsoluteVector {
        return {
            x: obj.position.x,
            y: (obj.position.z * -1),
            z: obj.position.y
        }
    }

    private createRotation(transformation: Transformation|undefined): AbsoluteVector {
        if (!transformation) {
            transformation = {
                rotationX: 0,
                rotationY: 0,
                rotationZ: 0,
            } as Transformation
        }
        return {
            x: transformation.rotationX,
            y: transformation.rotationZ,
            z: transformation.rotationY
        }
    }

    private createMaterialList(materials: IJungMaterialOptions[]): Material[] {
        const list = [];
        for (const material of materials) {
            if (depthMask === material.definition.name) {
                continue;
            }

            const createdMaterial = {
                target: material.area,
                material: material.definition.name,
                color: material.color?.hex,
                textureList: this.createTextureList(material)
            }
            if (material.color && material.color.hex) {
                createdMaterial.color = material.color.hex;
            }

            list.push(createdMaterial);
        }

        return list;
    }

    private createTextureList(material:IJungMaterialOptions): Texture[] {
        const list: Texture[] = [];
        if (material.aoMap && material.aoPath) {
            const aoTexture: Texture = {target: 'OCCLUSION', source: this.createAssetUrl(material.aoPath)}
            list.push(aoTexture);
        }
        if (material.texture && material.texturePath) {
            const defaultTexture: Texture = {target: 'DEFAULT_TEXTURE', source: this.createAssetUrl(material.texturePath)}
            if (material.textureRepeat) {
                const mapping: UvMapping = {
                    target: 'dynamicallyCreated',
                    scale: { x: material.textureRepeat.x, y: material.textureRepeat.y, z: 1}
                }
                if (material.textureRepeat.y > 1) {
                    mapping.position = {
                        x: 0,
                        z: 0,
                        y: 1 - material.textureRepeat.y
                    }
                }
                defaultTexture.mapping = mapping;
            }
            list.push(defaultTexture);
        }
        if (material.emissive && material.emissivePath) {
            const defaultTexture: Texture = {target: 'EMISSIVE', source: this.createAssetUrl(material.emissivePath)}
            list.push(defaultTexture);
        }

        return list;
    }

    private getOptions(): Options {
        let version = this.getVersion();
        return {
            target: 'GLB',
            version: version,
            glb_options: this.getGlbOptions()
        };
    }

    private getVersion(): string {
        if (!process.env.REACT_APP_AR_REVISION) {
            console.error('Could not fetch ar revision from environment!');
            return 'unknown';
        } else {
            if ('RANDOM' === process.env.REACT_APP_AR_REVISION) {
                return Math.random().toString()
            }
            return process.env.REACT_APP_AR_REVISION;
        }
    }

    private getGlbOptions(): GlbOptions {
        return {
            format: "GLB",
            export_tangents: false,
            export_colors: false,
            export_cameras: false,
            export_morph: false,
            export_morph_normal: false,
            export_morph_tangent: false,
            export_materials: "EXPORT",
            export_yup: true,
            export_animations: false,
            export_draco_mesh_compression_enable: false,
            export_draco_mesh_compression_level: 6,
            export_draco_position_quantization: 14,
            export_draco_normal_quantization: 10,
            export_draco_texcoord_quantization: 12,
            export_draco_generic_quantization: 12
        }
    }

    private createAssetUrl(texturePath: string): string {
        return `${getAssetHostUrl()}/${texturePath}`
    }
}