import React from 'react';
import styles from './QRFeedback.module.scss';
import { Status } from './types';
import QRCode from './QRCode';
import { useLocalization } from '../../stores/LocalizationStore/LocalizationProvider';
import LoadingCurl from '../LoadingCurl/LoadingCurl';
import { getCSSVariable } from '../../utils/css';

interface IProps {
  status: Status,
  link?: string | null,
  arSize?: number
}

const AR_SIZE = Number(getCSSVariable("--popupWidth")) - 72 // width - margins ( outside content 2x36)

const QRFeedback: React.FC<IProps> = ({ status, link, arSize = AR_SIZE }) => {
  const t = useLocalization();

  if (status === 'generated' && !link) {

    throw new Error('If status is generated a link has to be given.');
  }

  const feedbackStyles = {
    width: arSize + 'px',
    height: arSize + 'px'
  };

  const loadingContainerStyles = {
    width: arSize + 'px',
    height: arSize + 'px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const qrOptions = {
    width: arSize,
    margin: 0
  };

  return (

    <div className={styles.qrFeedback} style={feedbackStyles}>
      {
        status === 'generated' && link && <a href={link ?? ''} target="_blank" rel="noreferrer">
          <div className={styles.finished} style={feedbackStyles}>
            <QRCode options={qrOptions} text={link ?? ''} />
          </div>
        </a>
      }
      {
        status === 'failed' && <div className={styles.failed}>
          {t('ar_generation_error')}
        </div>
      }
      {
        status === 'generating' && (
          <div style={loadingContainerStyles}>
            <LoadingCurl />
          </div>
        )
      }
    </div>
  )
}

export default QRFeedback;
