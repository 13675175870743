import React from 'react';
import styles from './ControlsAreaLayout.module.scss';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode,
  showMenus: boolean
}

const ControlsAreaLayout = React.forwardRef<HTMLDivElement, IProps>(({ children, showMenus }, ref) => {

  const wrapperStyles = classNames(
    styles.controlLayout,
    !showMenus ? styles.hiddenMenus : ''
  );

  return (
    <div ref={ref} className={wrapperStyles}>
      {children}
    </div>
  );
});

export default ControlsAreaLayout;
