import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/RootStoreProvider';
import CurtainModal from '../../components/modals/CurtainModal';

const AppLoading: React.FC = observer(() => {
  const { viewerStore } = useRootStore();

  return (
    <CurtainModal
      hidden={viewerStore.status === 'started' && !viewerStore.assigning}
      animated={viewerStore.status !== 'started'}
    />
  );
});

export default AppLoading;
