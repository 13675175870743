import React from 'react';
import styles from './ActionsWrapper.module.scss'

interface IProps {
  children: React.ReactNode,
}

const ActionsWrapper: React.FC<IProps> = ({ children }) => {

  return (
    <div className={styles.actionButtonWrapper}>
      {children}
    </div>
  );
};

export default ActionsWrapper;
