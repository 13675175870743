import { useEffect, useState } from 'react';
import { FadingProps } from './types';

export const useFading = (visible: boolean, fadeTime: number): FadingProps => {
  const [inDom, setInDom] = useState<boolean>(visible);
  const [hidden, setHidden] = useState<boolean>(visible);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    if (!visible) {
      setHidden(true);
      timeout = setTimeout(() => {
        setInDom(false);
      }, fadeTime);
    } else {
      setInDom(true);
      setHidden(false);
    }

    return () => {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
    };
  }, [visible, fadeTime]);

  return { inDom, hidden };
};
