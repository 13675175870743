import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';

const DOM_ROOT_ELEMENT_ID = 'root'

interface IProps {
  children: any,
}

const Modal:React.FC<IProps> = ({children}) => {
  const root = document.getElementById(DOM_ROOT_ELEMENT_ID) as HTMLElement;

  return ReactDOM.createPortal(
    (
      <div className={styles.overlay}>
          {children}
      </div>
    ),
    root
  )
};

export default Modal;