import RootStore from '../RootStore';
import { action, makeObservable, observable } from 'mobx';
import { LocaleSchema, LocalizationStoreStatus } from './types';

export class LocalizationStore {
  public status: LocalizationStoreStatus = 'uninitialized';
  public name: string | null = null;
  public data: LocaleSchema | null= null;

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      status: observable,
      name: observable,
      data: observable,
      setStatus: action,
      setName: action,
      setData: action,
      initialize: action,
    });
  }

  getData(): LocaleSchema {
    if (!this.data) {

      throw new Error('Locale not found. Is the LocalizationStore initialized?');
    }

    return this.data;
  }

  setStatus = (status: LocalizationStoreStatus) => {
    this.status = status;
  }

  setName(name: string): void {
    this.name = name;
  }

  setData(data: LocaleSchema) {
    this.data = data;
  }

  translate = (str: string): string => this.getData()[str] ?? str;

  async initialize(name: string): Promise<void> {
    if(this.status !== 'uninitialized') {

      throw new Error('Context store is initialized yet.');
    }
    this.setStatus('initializing');
    const resp = await this.rootStore.requestStore.getLocale(name);
    if(resp.status === 'ok') {
      this.setName(name);
      this.setData(resp.payload);
      this.setStatus('initialized');
      console.info('Localization store initialized.');
    }
    else {
      console.error('Could not load locale.');
      this.setStatus('failed');
    }
  }
}
