import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RootStoreProvider } from './stores/RootStoreProvider';
import AppInit from './containers/AppInit';
import { LocalizationProvider } from './stores/LocalizationStore/LocalizationProvider';
import Debug from './containers/DebugImages';
import {getRootUrl} from './utils/url';

console.log('root', getRootUrl())

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RootStoreProvider>
      <AppInit>
        <LocalizationProvider>
          <Debug/>
          <App />
        </LocalizationProvider>
      </AppInit>
    </RootStoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
