import { Combination, CombinationGroup, ProgramSchema } from '../../assets/types/programSchema.json';
import { OCombinationGroup } from './OCombinationGroup';

export class ODomainData {
  private readonly groupTypeSet: Set<string> | null;

  constructor(
    public readonly data: ProgramSchema,
    public readonly groupTypes: string[] | null) {
    this.groupTypeSet = groupTypes === null ? null : new Set<string>(groupTypes);
  }

  get sortedCombinationGroups(): CombinationGroup[] {

    return [...this.data.combinationGroups]
      .filter(item => this.groupTypeSet === null || this.groupTypeSet.has(item.groupType))
      .sort((a, b) => a.sortIndex - b.sortIndex);
  }

  findCombinationGroupsByColor(color: string): CombinationGroup[] {

    return this.sortedCombinationGroups
      .filter(g => new OCombinationGroup(g).findCombinationByColor(color) !== null);
  }

  findOCombinationGroupByKey(key: string): OCombinationGroup | null {
    const group = this
      .data
      .combinationGroups
      .find(g => g.functionKey === key);

    return group ? new OCombinationGroup(group) : null;
  }

  findCombinationByKey(groupKey: string, combinationKey: string): Combination | null {

    return this
      .findOCombinationGroupByKey(groupKey)
      ?.findCombinationByKey(combinationKey) ?? null;
  }

  getFirstSortedGroup(): OCombinationGroup {
    const groups = this.sortedCombinationGroups;
    if (groups.length < 1) {

      throw new Error('Domain data contains no combination group.');
    }

    return new OCombinationGroup(groups[0]);
  }
}
