import {
    AnimationManager,
    Annotation,
    BasicSceneContainer,
    DefinitionFileLoader,
    IDimensions2D,
    ImagesLoader,
    MaterialFinder,
    MaterialLibrary,
    MeshUtils,
    ModelLoader,
    ObjectDisposer,
    ObjectFinder,
    ObjectMorpher,
    ResourceLoader,
    SceneOptions,
    SimpleScene,
    TextureLoader
} from 'webgl-helpers';
import Scene from './scene/Scene';
import Viewer from './viewer/Viewer';
import { SceneContainer } from './scene/SceneContainer';
import { ContextSchema } from '../../assets/types/contextSchema.json';
import BoxMapper from 'webgl-helpers/lib/material/mapping/BoxMapper';
import { MaterialManager } from './material/MaterialManager';
import { JungMatUpdater } from './material/JungMatUpdater';
import RequestStore from '../../stores/RequestStore';
import requestStore from '../../stores/RequestStore';
import { MaterialOption } from '../../assets/types/materialOptionsSchema.json';
import { MaterialOptionHandler } from './material/MaterialOptionHandler';
import { BlenderTransformer } from './transformer/BlenderTransformer';
import { TextureLoader as TextureFileLoader } from './material/TextureLoader';
import { NameSerializer } from './transformer/NameSerializer';
import { ARDetermination } from './scene/ARDetermination';
import { ArCapabilityHandler } from '../../stores/ViewerStore/types';

export {Viewer};


export const createViewer = async (parent: HTMLElement, dimensions: IDimensions2D, context: ContextSchema, requestStore: RequestStore, arCapabilityHandler: ArCapabilityHandler): Promise<Viewer> => {
    const loader = new DefinitionFileLoader();
    const options = new SceneOptions(loader, context.options);
    const annotation = new Annotation();
    const viewer = new Viewer(options, annotation, new BlenderTransformer(new NameSerializer(context.arOptions)), arCapabilityHandler);
    // @ts-ignore
    const scene: Scene<string, string, string, BasicSceneContainer> = await createScene(loader, requestStore, context);
    await viewer.init(parent, dimensions, [], scene);
    // @ts-ignore
    window.viewer = viewer;

    return viewer;
};

const createScene = async (definitionLoader: DefinitionFileLoader<any, any, any>, requestStore: RequestStore, context: ContextSchema): Promise<SimpleScene<any, any, any, any>> => {
    const finder:ObjectFinder<string> = new ObjectFinder();
    const modelLoader = new ModelLoader();
    const imageLoader = new ImagesLoader();
    const resourceLoader = new ResourceLoader(modelLoader, imageLoader)
    const meshUtils = new MeshUtils();
    const morpher = new ObjectMorpher(meshUtils);
    const animationManager = new AnimationManager();
    const textureLoader = new TextureLoader();
    const disposer = new ObjectDisposer()
    const container = new SceneContainer(disposer, meshUtils);
    const geoDefinitionPath = 'assets/options/definitions.yaml';
    const arDetermination = new ARDetermination(context.arOptions);

    return new Scene(
        finder,
        resourceLoader,
        morpher,
        animationManager,
        textureLoader,
        container,
        definitionLoader,
        geoDefinitionPath,
        await createMaterialManager(requestStore, context),
        arDetermination
    );
}

const createMaterialManager = async (requestStore: requestStore, context: ContextSchema): Promise<MaterialManager> => {
    const response = await requestStore.getMaterialOptions(context.materialOptions);
    let options: MaterialOption[] = [];
    if (response.status === 'ok') {
        options = response.payload;
    }
    const updater = new JungMatUpdater(new MaterialFinder(), BoxMapper.create());
    updater.skipTypeCheck = true;
    return new MaterialManager(
        new MaterialLibrary(),
        new ResourceLoader(new ModelLoader()),
        updater,
        new MaterialOptionHandler(options),
        new TextureFileLoader()
    )
}
