import React from 'react';
import styles from './MenuContainerLayout.module.scss';

interface IProps {
  children: React.ReactNode;
}

const MenuContainerLayout = React.forwardRef<HTMLDivElement, IProps>(({ children }, ref) => {

  return (
    <div ref={ref} className={styles.menuContainerLayout}>
      {children}
    </div>
  );
});

export default MenuContainerLayout;
