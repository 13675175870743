import { ArOptions } from '../../../assets/types/contextSchema.json';

export class NameSerializer {
  constructor(private programSerialize?: ArOptions) {}

  public serializeGeometryFilePath(inputPath: string): string {
    const newPath = inputPath.replace('.glb', '.blend');
    return this.serializeGeometryFileName(newPath);
  }

  public serializeGeometryName(name:string): string {
    if (!this.programSerialize || !this.programSerialize.geometryFilePostfix) {
      return name;
    }

    return name + this.programSerialize.geometryFilePostfix;
  }

  private serializeGeometryFileName(filePath: string): string {
    if (!this.programSerialize || !this.programSerialize.geometryFilePostfix) {
      return filePath;
    }

    const fileName = filePath.split('/').pop();
    if (!fileName) {
      throw new Error('Unable to extract file name from path!');
    }
    const basename = fileName.slice(0, fileName.lastIndexOf("."));
    const extension = fileName.slice(fileName.lastIndexOf("."));
    const newName = basename + this.programSerialize.geometryFilePostfix + extension;

    return filePath.replace(fileName, newName);
  }
}