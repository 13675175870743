import React from 'react';
import DebugViewerUi from '../../components/DebugViewer';
import { useRootStore } from '../../stores/RootStoreProvider';
import { DEBUG } from '../../utils/debug';
import { observer } from 'mobx-react-lite';

interface IProps {
}

const DebugViewer: React.FC<IProps> = observer(() => {
  const { viewerStore } = useRootStore();
  if (!DEBUG) {

    return null;
  }

  return (
    <DebugViewerUi>
      <a href={'./contexts.html'}>Contexts</a>
      {viewerStore.debugAdminCombinationUrl !== null && (
        <a target="_blank" rel="noreferrer" href={viewerStore.debugAdminCombinationUrl}>Admin</a>
      )}
      Geometries: {viewerStore.debugViewerStats?.geometries}<br/>
      Textures: {viewerStore.debugViewerStats?.textures}<br/>
      {<button onClick={() => {viewerStore.getViewer().toggleFrame();}}>Toggle frame</button>}
      {<button onClick={() => {viewerStore.getViewer().toggleModules();}}>Toggle module</button>}
      {<button onClick={() => {viewerStore.getViewer().toggleCutWall();}}>Toggle cut wall</button>}
      {<button onClick={() => {viewerStore.getViewer().toggleBackWall();}}>Toggle back wall</button>}
      {<button onClick={() => {viewerStore.getViewer().increaseBackPosition();}}>back wall + 1mm</button>}
      {<button onClick={() => {viewerStore.getViewer().decreaseBackWallPosition();}}> back wall - 1mm</button>}
      {<button onClick={() => {viewerStore.getViewer().clear();}}>Clear</button>}
    </DebugViewerUi>
  );
});

export default DebugViewer;
