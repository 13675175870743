import {IAppErrorResponse, IAppOkResponse, IAppUnreachableResponse} from './types';

export class AppOkResponse<P> implements IAppOkResponse<P> {
    readonly status: 'ok' = 'ok';
    readonly payload: P;
    readonly response: Response;

    constructor(payload: P, response: Response) {
        this.payload = payload;
        this.response = response;
    }
}

export class AppErrorResponse implements IAppErrorResponse {
    readonly status: 'error' = 'error';
    readonly payload: null = null;
    readonly response: Response;

    constructor(response: Response) {
        this.response = response;
    }
}

export class AppUnreachableResponse implements IAppUnreachableResponse {
    readonly status: 'unreachable' = 'unreachable';
    readonly payload: null = null;
    readonly response: null = null;
}
