import React from 'react';
import Icon from '../../Icon/Icon';
import IconButton from '../IconButton';
import { useLocalization } from '../../../stores/LocalizationStore/LocalizationProvider';
import { FADE_TIME } from '../../modals/PopupModal';

interface IProps {
  visible?: boolean,

  [index: string]: unknown
}

const ArButton: React.FC<IProps> = ({ visible = true, ...props }) => {
  const t = useLocalization();

  const delayTime = visible ? `${FADE_TIME + 200}ms` : '0ms';

  return (
    <IconButton
      {...props}
      style={{
        opacity: visible ? 1 : 0,
        transition: `opacity ${FADE_TIME}ms ${delayTime}, background 0s 0s`,
      }}
      title={t('ar_start')}
    >
      <Icon size={'large'} type={'openAr'} />
    </IconButton>
  );
};

export default ArButton;
