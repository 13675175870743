import { stripTrailingSlash } from './string';

export const getRootUrl = () => {

  return stripTrailingSlash(document.location.origin);
}

export const getAssetHostUrl = (): string => {
  if (process.env.REACT_APP_DEBUG_ASSETS_HOST) {
    return stripTrailingSlash(process.env.REACT_APP_DEBUG_ASSETS_HOST);
  }
  return getRootUrl();
}
