import Ajv from 'ajv';
import contextSchemaDef from '../../assets/schemas/contextSchema.json';
import {ContextSchema} from "../../assets/types/contextSchema.json";
import programSchemaDef from '../../assets/schemas/programSchema.json';
import {ProgramSchema} from "../../assets/types/programSchema.json";
import arKeySchemaDef from '../../assets/schemas/arKeySchema.json';
import { ArKeySchema } from '../../assets/types/arKeySchema.json';
import materialOptionSchemaDef from "../../assets/schemas/materialOptionsSchema.json";
import {MaterialOptionsSchema} from "../../assets/types/materialOptionsSchema.json";

const ajv = new Ajv();

export type ArPlacement = 'floor' | 'wall';
export type ArScale = 'fixed' | 'auto';

/******************************************************************************
 * Section Guards
 *****************************************************************************/

// RespDomainCombination
// // export interface RespDomainCombination {
// //   active: boolean,
// //   colorText: string,
// //   colorIcon: string,
// // }
// //
// // const RespDomainCombinationSchema: JSONSchemaType<RespDomainCombination> = {
// //   type: 'object',
// //   properties: {
// //     active: { type: 'boolean' },
// //     colorText: { type: 'string' },
// //     colorIcon: { type: 'string' },
// //   },
// //   required: ['active', 'colorText', 'colorIcon'],
// //   additionalProperties: true
// // };
// //
// // // RespDomainGroup
// // export interface RespDomainGroup {
// //   active: boolean,
// //   name: string,
// //   icon: string,
// //   combinations: RespDomainCombination[],
// // }
// // const RespDomainGroupSchema: JSONSchemaType<RespDomainGroup> = {
// //   type: 'object',
// //   properties: {
// //     active: { type: 'boolean' },
// //     name: { type: 'string' },
// //     icon: { type: 'string' },
// //     combinations: {
// //       type: 'array',
// //       items: RespDomainCombinationSchema
// //     }
// //   },
// //   required: ['active', 'name', 'icon'],
// //   additionalProperties: true
// // };
//
// // RespDomainData
// export type RespDomainData = RespDomainGroup[];
// const RespDomainDataSchema: JSONSchemaType<RespDomainData> = {
//   type: 'array',
//   items: RespDomainGroupSchema,
// };
// export const isRespDomainData = (resp: unknown): resp is RespDomainData => {
//   const valid = ajv.validate(RespDomainDataSchema, resp);
//   if(!valid) {
//     console.error('RespDomainData is invalid.', ajv.errors);
//   }
//
//   return valid;
// };

// DomainDataSchema
export const isDomainDataSchema = (data: unknown): data is ProgramSchema => {
  const valid = ajv.validate(programSchemaDef, data);
  if(!valid) {
    console.error('Data does not satisfy ProgramSchema.', ajv.errors);
  }

  return valid;
};

// RespContext
export const isContextSchema = (data: unknown): data is ContextSchema => {
  const valid = ajv.validate(contextSchemaDef, data);
  if(!valid) {
    console.error('Data does not satisfy ContextSchema.', ajv.errors);
  }

  return valid;
}

export const isMaterialOptionSchema = (data: unknown): data is MaterialOptionsSchema => {
  const valid = ajv.validate(materialOptionSchemaDef, data);
  if(!valid) {
    console.error('Data does not satisfy MaterialOptionSchema.', ajv.errors);
  }

  return valid;
}

export const isArKeySchema = (data: unknown): data is ArKeySchema => {
  const valid = ajv.validate(arKeySchemaDef, data);
  if(!valid) {
    console.error('Data does not satisfy ArKeySchema.', ajv.errors);
  }

  return valid;
}

/******************************************************************************
 * Section: Responses
 *****************************************************************************/
export interface AppBaseResponse<S extends string, P, R = Response | null> {
  status: S,
  payload: P,
  response: R,
}

export type IAppOkResponse<P> = AppBaseResponse<'ok', P, Response>;
export type IAppErrorResponse = AppBaseResponse<'error', null, Response>;
export type IAppUnreachableResponse = AppBaseResponse<'unreachable', null, null>;
type AppResponse = IAppOkResponse<unknown> | IAppErrorResponse | IAppUnreachableResponse;
export const isAppOkResponse = (resp: AppResponse): resp is IAppOkResponse<unknown> => resp.status === 'ok';
export const isAppErrorResponse = (resp: AppResponse): resp is IAppOkResponse<unknown> => resp.status === 'error';
export const isAppUnreachableResponse = (resp: AppResponse): resp is IAppOkResponse<unknown> => resp.status === 'unreachable';
