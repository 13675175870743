import React from 'react';
import styles from './IconButton.module.scss';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode,
  disabled?: boolean

  [index: string]: unknown
}

const IconButton: React.FC<IProps> = ({ children, disabled = false, ...props }) => {
  const p = { ...props };
  if (disabled) {
    p.onClick = undefined;
  }

  return (
    <div {...p} className={classNames(styles.buttonWrapper, disabled ? styles.disabled : '')}>
      {children}
    </div>);
};

export default IconButton;
