import React, { useEffect, useState } from 'react';

export const useBoundingClientRect = (ref: React.RefObject<HTMLElement>, enabled: boolean = true) => {
  const [rect, setRect] = useState<DOMRect | null>(null);
  useEffect(() => {
    if (!ref.current) {
      throw new Error('Ref not found.');
    }
    let observer: ResizeObserver;
    if (enabled) {
      setRect(ref.current.getBoundingClientRect());
      observer = new ResizeObserver(() => {
        if (!ref.current) {
          throw new Error('Ref not found');
        }
        if (!enabled) {

          return;
        }
        setRect(ref.current.getBoundingClientRect());
      });
      observer.observe(window.document.body);
    }

    return () => {
      observer?.disconnect();
    };
  }, [ref, enabled]);

  return rect;
};

