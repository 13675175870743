export const getCSSVariable = (variable: string) => {

  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable);
};

export const setCSSVariable = (variable:string, value: string) => {
  document.documentElement.style.setProperty(variable, value)
}

export const respondFrom = (device: 'mobile-small' | 'mobile') => {
  const width = window.innerWidth;
  const responseWidth = Number(getCSSVariable(`--breakpoint-${device}`));

  return width >= responseWidth;
}
