import React, { useEffect, useRef } from 'react';
import qr, { QRCodeRenderersOptions } from 'qrcode';

export interface IProps {
  text: string,
  options?: QRCodeRenderersOptions,

  [index: string]: any,
}

const defaultOptions: QRCodeRenderersOptions = {
  width: 150,
  margin: 0
};

const QRCode: React.FC<IProps> = ({ text, options = {}, ...props }) => {
  const ref = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const opts = { ...defaultOptions, ...options };
    ref && ref.current && qr.toCanvas(ref.current, text, opts, (error) => {
      if (error) {
        console.log('Error: Can\'t create QR code.');
      }
    });
  }, [ref, text, options]);


  return (
    <canvas {...props} ref={ref} />
  );
};

export default QRCode;