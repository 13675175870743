import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SelectInput from '../../components/SelectInput';
import SelectContainerLayout from '../../components/layouts/SelectContainerLayout';
import { useRootStore } from '../../stores/RootStoreProvider';
import { ExpandType } from './types';
import ArPopup from '../../components/ArPopup';
import ArButton from '../../components/buttons/ArButton';
import { ActionButton, ActionsWrapper } from '../../components/Action';
import ExpandMenuButton from '../../components/buttons/ExpandMenuButton';
import MenuContainerLayout from '../../components/layouts/MenuContainerLayout';
import { useBoundingClientRect } from '../../hooks/useBoundingClientRect';
import ControlsAreaLayout from '../../components/layouts/ControlsAreaLayout';

export const DEBUG_DO_NOT_HIDE_MENUS = (process.env.REACT_APP_DEBUG_DO_NOT_HIDE_MENUS ?? '') === 'on';

const Actions: React.FC = observer(() => {
  const [expanded, setExpanded] = useState<ExpandType | null>(null);
  const [showMenus, setShowMenus] = useState<boolean>(true);
  const { domainStore, arStore, viewerStore } = useRootStore();
  const config = domainStore.getConfiguration();
  const arButtonHandler = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setExpanded('ar');
    return arStore.generate();
  };

  useEffect(() => {
    if (expanded === 'combination' || expanded === 'group') {
      setExpanded(null);
    } else if (!DEBUG_DO_NOT_HIDE_MENUS && viewerStore.clickCount > 0) {
      setShowMenus(false);
    }
    // eslint-disable-next-line
  }, [viewerStore.clickCount]);

  const refArButton = useRef<HTMLDivElement>(null);
  const arButtonRect = useBoundingClientRect(refArButton, expanded === 'ar');
  const showArButton = expanded !== 'ar' && viewerStore.arEnabled;

  return (
    <ControlsAreaLayout showMenus={showMenus}>
      <MenuContainerLayout>
        {showMenus && (
          <SelectContainerLayout>
            <SelectInput
              current={config.groupKey}
              values={domainStore.uiGroups}
              expanded={expanded === 'group'}
              onExpand={expanded => setExpanded(expanded ? 'group' : null)}
              onSelect={(id) => domainStore.setGroupKey(String(id))}
            />
            <SelectInput
              current={config.combinationKey}
              values={domainStore.uiCombinations}
              expanded={expanded === 'combination'}
              onExpand={expanded => setExpanded(expanded ? 'combination' : null)}
              onSelect={(id) => domainStore.setCombinationKey(String(id))}
            />
          </SelectContainerLayout>
        )}
        {!showMenus && (
          <ActionButton style={{ opacity: !showMenus ? 0 : 1 }}>
            <ExpandMenuButton onClick={() => setShowMenus(true)} />
          </ActionButton>
        )}
      </MenuContainerLayout>
      <ActionsWrapper>
        <ActionButton ref={refArButton}>
          <ArButton
            visible={showArButton}
            onClick={arButtonHandler}
          />
        </ActionButton>
      </ActionsWrapper>
      <ArPopup
        status={arStore.status === 'notGenerated' ? 'generating' : arStore.status}
        url={arStore.url}
        hidden={expanded !== 'ar'}
        top={arButtonRect?.top ?? 0}
        right={arButtonRect?.right ?? 0}
        onClose={() => setExpanded(null)}
      />
    </ControlsAreaLayout>
  );
});

export default Actions;
