import * as React from 'react';
import classNames, { Argument } from 'classnames';

import s from './Icon.module.scss';

import { ReactComponent as arrow } from './icons/arrow.svg';
// import { ReactComponent as arrow } from './icons/arrow_down.svg';
import { ReactComponent as back } from './icons/back.svg';
import { ReactComponent as arrowBack } from './icons/arrowBack.svg';
import { ReactComponent as close } from './icons/close_ar.svg';
import { ReactComponent as openAr } from './icons/open_ar.svg';

const icons = {
  arrow,
  back,
  arrowBack,
  close,
  openAr
};

export type IconName = keyof typeof icons;

export type IconSize = 'auto' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

interface IProps {
  type: IconName;
  className?: string;
  size?: IconSize;

  [index: string]: any,
}

interface IFigureProps {
  type: IconName;
  className?: string;
}

const Icon: React.FC<IProps> = ({ type, size = 'small', className, ...props }) => {
  const classes = classNames(s.icon, size as Argument, className as Argument);
  const SVGIcon = icons[type];

  return (
    <i className={classes} {...props}>
      <SVGIcon className={classNames(s.svg, {
        [s.auto]: size === 'auto',
        [s.small]: size === 'small',
        [s.xsmall]: size === 'xsmall',
        [s.medium]: size === 'medium',
        [s.large]: size === 'large',
        [s.xlarge]: size === 'xlarge'
      })} />
    </i>
  );
};

export const Figure: React.FC<IFigureProps> = (props) => {
  return <Icon {...props} size="auto" />;
};
export default Icon;
