import RootStore from '../RootStore';
import { action, makeObservable, observable } from 'mobx';
import { Configuration } from '../DomainStore/types';
import SimpleZipStorage from '../../services/ZipService';
import { DebugImagesStoreStatus } from './types';
import { DEBUG } from '../../utils/debug';

export class DebugImagesStore {
  public status: DebugImagesStoreStatus = 'notGenerated';
  public doneNum: number = 0;
  public totalNum: number = 0;
  public readonly enabled = DEBUG;
  private readonly delay = 100;

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      status: observable,
      doneNum: observable,
      totalNum: observable,
      setStatus: action,
      setDoneNum: action,
      setTotalNum: action,
      generate: action
    });
  }

  setStatus(status: DebugImagesStoreStatus) {
    this.status = status;
  }

  setDoneNum(num: number) {
    this.doneNum = num;
  }

  setTotalNum(num: number) {
    this.totalNum = num;
  }

  async generate(): Promise<void> {
    if(!this.enabled) {

      throw new Error('The debug mode is not enabled!');
    }
    if (this.status === 'generating') {

      throw new Error('The generation of the images is running currently!');
    }
    this.setDoneNum(0);
    this.setTotalNum(0);
    this.setStatus('generating');
    const zipStore = new SimpleZipStorage();
    const domainData = this.rootStore.domainStore.getDomainData();
    const { combinationGroups } = domainData.data;
    const configs: Configuration[] = [];
    for (const g of combinationGroups) {
      for (const c of g.combinations) {
        configs.push({
          groupKey: g.functionKey,
          combinationKey: c.coloredFunctionKey
        });
      }
    }
    this.setTotalNum(configs.length);
    for (let i = 0; i < configs.length; i++) {
      const config = configs[i];
      const url = await this.getDelayedScreenshot(config);
      zipStore.addUrl(url, `${config.groupKey}_${config.combinationKey}_${i}.png`);
      this.setDoneNum(i + 1);
    }
    await zipStore.close();
    await zipStore.download(`${domainData.data.programId}.zip`);
    this.setDoneNum(0);
    this.setTotalNum(0);
    this.setStatus('notGenerated');
  }

  private async getDelayedScreenshot(config: Configuration): Promise<string> {
    return new Promise((resolve) => {
      this.rootStore.domainStore.setConfiguration(config)
        .then(() => {
          setTimeout(async () => {
            resolve(await this.rootStore.viewerStore.getViewer().getScreenshot());
          }, this.delay);
        });
    });
  }
}
