const defaultContextName = process.env.REACT_APP_DEFAULT_CONTEXT ?? null;
if (defaultContextName === null) {

    throw new Error(`Env variable REACT_APP_CL__CONTEXT is not defined.`)
}

export const getContextName = (): string => {
    let value: string | null = new URLSearchParams(window.location.search).get('c');

    return null === value ? defaultContextName : value;
}
