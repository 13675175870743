const defaultLocale = process.env.REACT_APP_DEFAULT_LOCALE ?? null;
if (defaultLocale === null) {

    throw new Error(`Env variable REACT_APP_DEFAULT_LOCALE is not defined.`)
}

export const getLocale = (): string => {
    let value: string | null = new URLSearchParams(window.location.search).get('l');

    return null === value ? defaultLocale : value;
}
