import JSZip from 'jszip';
import * as file from '../../utils/file';

export class SimpleZipStorage {
  private zip: JSZip = new JSZip();
  private blob: Blob | undefined;

  addUrl(url: string, fileName: string) {

    this.zip.file(
      fileName,
      file.dataURLToBlob(url)
    );
  }

  async close() {
    this.blob = await this.zip.generateAsync({ type: 'blob' });
  }

  getBlob(): Blob {
    if(this.blob === undefined) {

      throw new Error('ZipStorage instance is not closed. Can not return the blob.');
    }

    return this.blob;
  }

  download(fileName: string): void {
    file.downloadBlob(this.getBlob(), fileName);
  }
}
