import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/RootStoreProvider';
import DebugImagesUi from '../../components/DebugImages';

interface IProps {
}

const DebugImages: React.FC<IProps> = observer(() => {
  const { debugImagesStore } = useRootStore();
  useEffect(() => {
    if(!debugImagesStore.enabled) {

      return;
    }
    const handler = async (event: KeyboardEvent) => {
      if (event.key === 'p' && debugImagesStore.status !== 'generating') {
        await debugImagesStore.generate();
      }
    };
    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [debugImagesStore]);

  if(debugImagesStore.status !== 'generating') {

    return null;
  }

  return (
    <DebugImagesUi
      doneNum={debugImagesStore.doneNum}
      totalNum={debugImagesStore.totalNum}
    />
  )
});

export default DebugImages;
