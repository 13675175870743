import {MaterialOption} from "../../../assets/types/materialOptionsSchema.json";

export class MaterialOptionHandler {
    constructor(private options: MaterialOption[]) {}

    public getOptionForProduct(id: string): MaterialOption|undefined {
        return this.findOptionById(id);
    }

    public getOptionForProductWithMaterial(productId: string, materialId: string): MaterialOption|undefined {
        return this.findOptionWithMaterial(productId, materialId);
    }

    private findOptionWithMaterial(id: string, material: string) {
        return this.options.find(option => option.productId === id && option.materialId === material);
    }

    private findOptionById(id: string): MaterialOption|undefined {
        for(const item of this.options) {
            if (item.productId === id) {
                return item;
            }
       }
    }
}