import React from 'react';
import './App.scss';
import Layout from './components/layouts/Layout';
import Viewer from './containers/Viewer';
import Actions from './containers/Actions';
import DebugViewer from './containers/DebugViewer';
import AppLoading from './containers/AppLoading';

interface IProps {
}

const App: React.FC<IProps> = () => {

  return (
    <>
      <Layout>
        <Viewer />
        <DebugViewer />
        <Actions />
      </Layout>
      <AppLoading/>
    </>
  );
};

export default App;
