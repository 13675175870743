import React from 'react';
import FadeModal from '../FadeModal';
import styles from './CurtainModal.module.scss';
import LoadingCurl from '../../LoadingCurl';

export const FADE_TIME = 100;
export const OPACITY = 100;

export interface IProps {
  hidden?: boolean,
  animated?: boolean | undefined,
}

const CurtainModal: React.FC<IProps> = ({ animated, ...props }) => {

  return (
    <FadeModal
      {...props}
      fadeTime={FADE_TIME}
      opacity={OPACITY}
    >
      {animated && (
        <div className={styles.position}>
          <LoadingCurl />
        </div>
      )}
    </FadeModal>
  );
};

export default CurtainModal;
