import { MaterialLibrary, MaterialUpdater, ResourceLoader, ResourceLoadTransaction } from 'webgl-helpers';
import * as THREE from 'three';
import { IJungGeometry, IJungMaterialOptions } from '../types';
import { MaterialAssignment } from '../../../assets/types/programSchema.json';
import util from 'util';
import { MaterialOptionHandler } from './MaterialOptionHandler';
import { TextureLoader } from './TextureLoader';
import { MaterialOption } from '../../../assets/types/materialOptionsSchema.json';

type GeoIdentifier = {
    geoId: string
    productId: string
    assetName: string
}

export const depthMask = 'DepthMask';

export class MaterialManager {

    private _renderer!: THREE.WebGLRenderer

    constructor(
        private materialLibrary: MaterialLibrary,
        private loader: ResourceLoader,
        private updater: MaterialUpdater<string>,
        private optionHandler: MaterialOptionHandler,
        private textureLoader: TextureLoader
    ) {
    }

    set renderer(value: THREE.WebGLRenderer) {
        this._renderer = value;
    }

    public async loadMaterials(libraryPath: string, onProgress = () => {
    }) {
        const transaction = new ResourceLoadTransaction([
            ResourceLoadTransaction.Model(libraryPath)
        ] as const);

        const res = await this.loader.load(transaction, onProgress);
        if (!res) {

            throw new Error('Unable to read data from ' + libraryPath);
        }

        this.materialLibrary.addMaterialsFromGroup(res[0].group);
    }

    public async setMaterial(geo: THREE.Object3D, def: IJungGeometry): Promise<IJungMaterialOptions[]> {
        console.log(util.format('Materializing %s ', def.id))
        const materials = def.definition.assignment.data.materials;
        const createdOptions: IJungMaterialOptions[]= [];

        for (const assignment of materials) {
            const materialAsset = this.getMaterialAsset(assignment);
            if (!materialAsset) {
                console.warn(util.format('Could not find material %s in library!', assignment.assetName))
                continue;
            }
            const options = await this.createMaterialOptions(geo, assignment, materialAsset);
            if (options) {
                if (materialAsset.userData && materialAsset.userData.depthMask) {
                    options.depthMask = true;
                }
                createdOptions.push(options);
                this.updater.updateMaterial(geo, options);
            }
        }
        return createdOptions;
    }

    private getMaterialAsset(material: MaterialAssignment): THREE.Material | undefined {
        if (material.assetName === depthMask) {
            const material = new THREE.MeshBasicMaterial();
            material.blending = THREE.CustomBlending; // 5
            material.blendEquation = THREE.AddEquation; //default 100
            material.blendSrc = THREE.ZeroFactor;  // 200
            material.blendDst = THREE.OneFactor; // 201
            material.userData = { depthMask: true}

            return material;
        }
        const asset = this.materialLibrary.getMaterial(material.assetName);
        if (!asset) {
            return;
        }
        return asset.clone()
    }

    private async createMaterialOptions(geo: THREE.Object3D, materialData: MaterialAssignment, materialAsset: THREE.Material): Promise<IJungMaterialOptions|undefined> {
        const geoData:IJungGeometry = geo.userData as IJungGeometry;
        console.log(util.format('Find material %s for slot %s', materialData.assetName, materialData.slot))
        const geoIdentifier: GeoIdentifier = {geoId: geo.name, productId: geoData.id, assetName: geoData.definition.assignment.data.assetName}
        const area = this.findMaterialAreaName(geo, materialData.slot);
        if (!area) {
            console.warn(util.format('Could not find material area for slot %s!', materialData.slot))
            return;
        }
        const options: IJungMaterialOptions = {
            material: materialAsset,
            area: area,
            mode: 'set',
            definition: {name: materialData.assetName},
            color: materialData.color
        }
        if (!materialData.primary && !materialData.conditional) {
            await this.handleStaticTextures(geoIdentifier, materialData.assetName, options)
            await this.addTextureColor(geoIdentifier, options, materialData.assetName)
            return options;
        }

        // Don't add ao to other materials!
        await this.addAoTexture(geoIdentifier, options, materialData)
        await this.addTexture(geoIdentifier, options, materialData.assetName)
        await this.addTextureColor(geoIdentifier, options, materialData.assetName)
        await this.handleStaticTextures(geoIdentifier, materialData.assetName, options)

        return options;
    }

    private findMaterialAreaName(geo: THREE.Object3D, slot: number): string|undefined {
        let counter: number = 0;
        let name: string | undefined
        geo.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                // @ts-ignore
                let material: THREE.MeshPhysicalMaterial = child.material;
                if (counter === slot) {
                    name = material.name;
                }
                counter++;
            }
        })
        if (!name) {
            console.error(util.format('Could not find material area in slot %s in geo %s', slot, geo.name));
        }

        return name
    }

    private async addAoTexture(identifier: GeoIdentifier, options: IJungMaterialOptions, assignment: MaterialAssignment) {

        if (!assignment.primary && assignment.conditional) {
            const materialOptions = this.getOptions(identifier, '');
            if (!materialOptions || !materialOptions.addAoToConditional) {
                return;
            }
            console.log('Adding ao to conditional material area!');
        }
        const path = 'assets/materials/ao-textures/' + identifier.geoId + '.png'
        console.log(util.format('Load ao: %s', path));
        const ao = await this.textureLoader.getTextureFile(path);
        if (ao) {
            ao.encoding = THREE.LinearEncoding
            ao.flipY = false;
            options.aoMap = ao;
            options.aoPath = path;
            console.log(util.format('Loaded ao: %s', path));
        }
    }

    private async addTextureColor(identifier: GeoIdentifier,  options: IJungMaterialOptions, materialId: string) {
        const materialOption = this.getOptions(identifier, materialId);
        if (materialOption && materialOption.color) {
            console.log(`Add texture color for ${identifier.geoId}/${identifier.productId}`)
            options.textureColor = materialOption.color;
        }
    }

    private async addTexture(identifier: GeoIdentifier, options: IJungMaterialOptions, materialId: string) {
        await this.addDefaultTexture(identifier, options);
        const materialOption = this.getOptions(identifier, materialId)
        if (materialOption && materialOption.emissiveMap) {
            const emissivePath = `assets/materials/${materialOption.emissiveMap.map}`
            const emissiveTexture = await this.textureLoader.getTextureFile(emissivePath);
            if (emissiveTexture) {
                console.log(`Add emissive texture for ${identifier.geoId}/${identifier.productId}`)
                emissiveTexture.encoding = THREE.sRGBEncoding
                emissiveTexture.flipY = false;
                options.emissive = {
                    ...materialOption.emissiveMap,
                    texture: emissiveTexture,
                };
                options.emissivePath = emissivePath;
            }
        }
    }

    private async addDefaultTexture(identifier: GeoIdentifier, options: IJungMaterialOptions) {
        const texturePath = `assets/materials/color-textures/${identifier.productId}.jpg`;
        const texture = await this.textureLoader.getTextureFile(texturePath);
        const materialOption = this.getOptions(identifier, options.definition.name)
        if (texture) {
            console.log(`Add default texture for ${identifier.geoId}/${identifier.productId}`, materialOption)
            texture.encoding = THREE.sRGBEncoding
            texture.flipY = false;
            options.texture = texture;
            options.texturePath = texturePath;
            if (materialOption && materialOption.textureRepeat) {
                const vector = new THREE.Vector2(materialOption.textureRepeat.x, materialOption.textureRepeat.y);
                texture.repeat = vector;
                options.textureRepeat = vector;
            }
        }
    }

    private async handleStaticTextures(identifier: GeoIdentifier, materialId: string, options: IJungMaterialOptions) {
        const materialOption = this.getOptions(identifier, materialId);
        if (!materialOption || !materialOption.staticTextures) {
            console.log(util.format('No static textures defined for %s with %s', identifier.productId, materialId));
            return;
        }
        const areaTexture = materialOption.staticTextures.find((texture) => {
            return texture.area === options.area;
        });
        if (!areaTexture) {
            console.log(util.format('No static textures defined for %s with %s in %s', identifier.productId, materialId, options.area));
            return
        }
        console.log(util.format('Static texture definition found for %s with %s in %s', identifier.productId, materialId, options.area));
        const path = `assets/materials/${areaTexture.map}`
        const texture = await this.textureLoader.getTextureFile(path);
        if (texture) {
            console.log(`Add static texture ${path}`)
            texture.flipY = false;
            texture.encoding = THREE.sRGBEncoding
            options.texture = texture;
            options.texturePath = path;
            if (materialOption && materialOption.textureRepeat) {
                const vector = new THREE.Vector2(materialOption.textureRepeat.x, materialOption.textureRepeat.y);
                texture.repeat = vector;
                options.textureRepeat = vector;
            }
        }
    }

    private getOptions(identifier: GeoIdentifier, materialId: string): MaterialOption|undefined {
        let option;
        let materialSpecific = this.optionHandler.getOptionForProductWithMaterial(identifier.geoId, materialId)
        let productSpecific = this.optionHandler.getOptionForProductWithMaterial(identifier.productId, materialId);
        let assetSpecific = this.optionHandler.getOptionForProductWithMaterial(identifier.assetName, materialId)
        if (productSpecific) {
            option = productSpecific;
        } else if (assetSpecific) {
            option = assetSpecific;
        }else if (materialSpecific) {
            option = materialSpecific;
        }
        if (option) {
            return option;
        }

        option = this.optionHandler.getOptionForProduct(identifier.productId);
        if (!option) {
            option = this.optionHandler.getOptionForProduct(identifier.geoId);
        }
        if (!option) {
            option = this.optionHandler.getOptionForProduct(identifier.assetName)
        }
        if (option && !option.materialId) {
            return option
        }

        return undefined;
    }
}