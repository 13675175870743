import { createContext, ReactNode, useCallback, useContext } from 'react';
import { TranslateFunc } from './types';
import { useRootStore } from '../RootStoreProvider';

const context = createContext<TranslateFunc | undefined>(undefined);

export function LocalizationProvider({ children }: { children: ReactNode }) {
  const { localizationStore } = useRootStore();

  const translate = useCallback((str: string) => {
    return localizationStore.translate(str);
  }, [localizationStore]);

  return <context.Provider value={translate}>{children}</context.Provider>;
}

export function TESTLocalizationProvider({ children }: { children: ReactNode }) {
  const translate = useCallback((str: string) => str, []);

  return <context.Provider value={translate}>{children}</context.Provider>;
}

/** Returns a translation function:
 * const trans = useLocalization();
 * trans('string to be translated');
 */
export function useLocalization() {
  const ctx = useContext(context);
  if (ctx === undefined) {

    throw new Error('useLocalization must be used within LocalizationProvider');
  }

  return ctx;
}
