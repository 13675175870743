import { BasicSceneContainer, IVector } from 'webgl-helpers';
import { BackWall, Frame, IJungGeometry, IJungSceneContent, IViewConfiguration, Module } from '../types';
import * as THREE from 'three';

export type ContainerConfiguration = {
  rotation: IVector
  position: IVector
}

export class SceneContainer extends BasicSceneContainer {

  public clearContainer(removeContainerFromScene?: boolean) {
    super.clearContainer(removeContainerFromScene);
    this.children = [];
    this._container = new THREE.Object3D();
    this._container.name = 'Scene container';
  }

  public applyConfiguration(config: IViewConfiguration) {
    const containerPosition = config.container.position;
    const containerRotation = config.container.rotation;
    const c = THREE.MathUtils.degToRad;
    this.container.position.set(containerPosition.x, containerPosition.y, containerPosition.z);
    this.container.rotation.set(c(containerRotation.x), c(containerRotation.y), c(containerRotation.z));
  }

  public getViewerContent(): IJungSceneContent {
    let frame;
    let modules = [];
    let backWall;
    for (const child of this.children) {
      const geoData = child.userData as IJungGeometry;
      if (geoData.definition) {
        if (geoData.definition.type === Frame) {
          frame = child;
        } else if (geoData.definition.type === Module) {
          modules.push(child);
        } else if (geoData.definition.type === BackWall) {
          backWall = child;
        }
      }
    }
    if (!frame) {
      throw new Error('Unable to detect combination frame!');
    }
    return {
      frame: frame,
      modules: modules,
      backWall: backWall
    };
  }

  public removeContent(content: THREE.Object3D) {
    const index = this.children.indexOf(content, 0);
    if (index > -1) {
      this.children.splice(index, 1);
      this.container.remove(content);
    }
  }
}