import React from 'react';
import styles from './ArPopup.module.scss';
import ArPopupContent from './ArPopupContent';
import CloseButton from '../buttons/CloseButton';
import PopupModal from '../modals/PopupModal';

export interface IProps {
  status: 'generating' | 'generated' | 'failed';
  url: string | null,
  hidden: boolean,
  top: number,
  right: number,
  onClose: () => void

}

const ArPopup: React.FC<IProps> = ({ status, url, top, right, hidden, onClose }) => {

  return (
    <PopupModal hidden={hidden}>
      <div
        style={{
          position: 'absolute',
          left: right,
          top
        }}
      >
        <div className={styles.arPopup}>
          <div className={styles.closeButton}>
            <CloseButton
              disabled={status === 'generating'}
              onClick={onClose}
            />
          </div>
          <div className={styles.popup}>
            <ArPopupContent
              status={status}
              url={url}
            />
          </div>

        </div>
      </div>
    </PopupModal>
  );
};

export default ArPopup;
