import {
    AnimationManager,
    DefinitionFileLoader,
    ICamera,
    IDimensions2D,
    IWebGlOptions,
    MeshUtils,
    ObjectFinder,
    ObjectMorpher,
    ResourceLoader,
    SimpleScene,
    TextureLoader
} from 'webgl-helpers';
import * as THREE from 'three';
import { AssetAssignment } from '../../../assets/types/programSchema.json';
import * as util from 'util';
import {
    Frame,
    GeoType,
    IJungGeometry,
    IJungGeometryData,
    IJungMaterialOptions,
    IViewConfiguration,
    IViewSettings,
    LoadedGeometry,
    Module
} from '../types';
import { MaterialManager } from '../material/MaterialManager';
import { ConfigTransformation } from '../transformation/ConfigTransformation';
import { InteractionManager } from 'three.interactive';
import { DEBUG } from '../../../utils/debug';
import { FEATURE_BACKWALLS, REACT_APP_DEBUG_BACKWALL, REACT_APP_DEBUG_CAMERA } from '../../../utils/features';
import { ScaleFactor } from '../transformation/ScaleFactor';
import { SceneContainer } from './SceneContainer';
import { ARDetermination } from './ARDetermination';

export default class Scene extends SimpleScene<string, string, string, SceneContainer> {

    private _assignments: AssetAssignment[] = [];
    private materialManager: MaterialManager
    private transformationHandler: ConfigTransformation;
    private interaction!: InteractionManager;
    private cameraHelper!: THREE.CameraHelper
    private _loadedGeometries: LoadedGeometry[] = [];
    private viewerSize?: IDimensions2D;
    private camSettingsName?: string;
    private cutWall?: THREE.Object3D
    private backWall?: THREE.Object3D;

    constructor(
        finder: ObjectFinder<string>,
        resourceLoader: ResourceLoader,
        morpher: ObjectMorpher,
        animationManager: AnimationManager,
        textureLoader: TextureLoader,
        container: SceneContainer,
        defLoader: DefinitionFileLoader<string, string, string>,
        geoDefinitionsPath: string,
        materialManager: MaterialManager,
        private arDetermination: ARDetermination
    ) {
        super(finder, resourceLoader, morpher, animationManager, textureLoader, container, defLoader, geoDefinitionsPath);
        this.materialManager = materialManager;
        this.transformationHandler = new ConfigTransformation(new ScaleFactor());
    }

    set assignments(value: AssetAssignment[]) {
        this._assignments = value;
    }
    get loadedGeometries(): LoadedGeometry[] {
        return this._loadedGeometries;
    }

    public async initialize(renderer: THREE.WebGLRenderer, options: IWebGlOptions, camera: ICamera): Promise<void> {
        await super.initialize(renderer, options, camera);
        this.materialManager.renderer = renderer;
        if (DEBUG) {
            this.interaction = new InteractionManager(
                renderer,
                camera.camera,
                renderer.domElement,
                {autoAdd: true, scene: this.scene, bindEventsOnBodyElement: true}
            );
            if (REACT_APP_DEBUG_CAMERA) {
                this.cameraHelper = new THREE.CameraHelper(camera.camera);
                this.scene.add(this.cameraHelper);
            }
        }

        await this.materialManager.loadMaterials(options.libraries[0]);
    }

    public setViewerSize(size: IDimensions2D) {
        this.viewerSize = size;
        if (this.camSettingsName) {
            this.fitCameraToContainer(this.camSettingsName);
        }
    }

    public updateAnimation() {
        this.animationManager.update();
        if (this.cameraHelper) {
            this.cameraHelper.update();
        }
    }

    clearScene(rebuildContainer?: boolean) {
        this._loadedGeometries = [];
        super.clearScene(rebuildContainer);
        this.camSettingsName = undefined;
        this.transformationHandler = new ConfigTransformation(new ScaleFactor());
        this.cutWall = undefined;
        this.backWall = undefined;
        if (this.interaction) {
            this.interaction.dispose();
            this.interaction = new InteractionManager(
                this.renderer, this.camera.camera,
                this.renderer.domElement,
                {autoAdd: true, scene: this.scene, bindEventsOnBodyElement: true}
            );
        }
    }

    public determineGeometryArCapabilities(): boolean {
        return this.arDetermination.arIsPossible(this.loadedGeometries);
    }

    public showScene(): void {
        this.container.applyConfiguration(this.options as IViewConfiguration);
        this.container.addContainerToScene();
        const moduleCount = this._assignments.length - 1;
        this.camSettingsName = moduleCount + '_modules';
        this.fitCameraToContainer(moduleCount + '_modules')
    }

    public toggleFrame(): void {
        this.scene.traverse((object: THREE.Object3D) => {
            const data = object.userData as IJungGeometry;
            if (data.id && Frame === data.definition.type) {
                object.visible = !object.visible;
            }
        })
    }

    public toggleModules(): void {
        this.scene.traverse((object: THREE.Object3D) => {
            const data = object.userData as IJungGeometry;
            if (data.id && Module === data.definition.type) {
                object.visible = !object.visible;
            }
        })
    }

    public toggleCutWall(): void {
        if (this.cutWall) {
            if (this.cutWall.parent) {
                this.container.removeContent(this.cutWall);
            } else {
                this.container.addContent(this.cutWall);
            }
        }
    }

    public toggleBackWall(): void {
        if (this.backWall) {
            if (this.backWall.parent) {
                this.container.removeContent(this.backWall);
            } else {
                this.container.addContent(this.backWall);
            }
        }
    }

    public updateBackWallPosition(vector: THREE.Vector3): void {
        if (this.backWall) {
            this.backWall.position.add(vector);
            const world = new THREE.Vector3();
            this.backWall.getWorldPosition(world)
            console.log('backwall position: ', this.backWall.position)
            console.log('backwall world position: ', world)
        }
    }

    public async loadGeo(id: string): Promise<void> {
        const mainGeo = await this.loadAndPrepareGeo(id);
        const childData = mainGeo.geometry.definition.assignment.data.child;
        if (childData) {
           const childGeo = await this.loadAndPrepareGeo(childData.productArticleNumber, id);
           if (childGeo.geometry.geo) {
               const parentGeo = mainGeo.geometry.geo as THREE.Object3D;
               //mainGeo.geometry.geo?.add(childGeo.geometry.geo)
               childGeo.transformation = this.transformationHandler.setChildTransformation(childGeo.geometry.geo, parentGeo, childGeo.geometry.definition.assignment);
               mainGeo.child = childGeo;
           }
        }
        await this.loadCutWall(mainGeo);
        this.createBackWall(mainGeo);
        this._loadedGeometries.push(mainGeo);
    }

    protected createBackWall(frame: LoadedGeometry): void {
        if (!DEBUG) {
            return;
        }
        if (Frame !== frame.geometry.definition.type) {
            return;
        }
        const geometry = new THREE.PlaneGeometry( 1, 1 );
        const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
        this.backWall = new THREE.Mesh(geometry, material);
        const frameGeo = frame.geometry.geo as THREE.Object3D;
        const assetPath = frame.geometry.definition.filePath;
        if (assetPath.indexOf('ls_zero') > -1) {
            this.transformationHandler.setLSZeroBackWallPosition(this.backWall, frameGeo)
        } else {
            this.transformationHandler.setBackWallTransformation(this.backWall, frameGeo);
        }
    }

    protected async loadCutWall(frame: LoadedGeometry): Promise<void> {
        if (!FEATURE_BACKWALLS) {
            return;
        }
        if (Frame !== frame.geometry.definition.type) {
            return;
        }
        if (!frame.geometry.definition.assignment.data.frameWall) {
            console.log(`Got frame without wall: ${frame.geometry.id}!`);
            return;
        }
        console.log(`Cut wall for ${frame.geometry.id}`)
        const wallData = frame.geometry.definition.assignment.data.frameWall;
        const id = wallData?.productArticleNumber as string;
        const geo = this.prepareGeo(id, frame.geometry.definition.assignment.productArticleNumber, true);
        geo.geo = await this.loadGeometry(geo);
        this.addInfoClickHandler(geo.geo);
        this.container.addContent(geo.geo)
        this.cutWall = geo.geo;
        await this.materialManager.setMaterial(geo.geo, geo);
        const frameGeo = frame.geometry.geo as THREE.Object3D;
        const helpers = this.transformationHandler.setBackWallTransformation(geo.geo, frameGeo);
        if (DEBUG && REACT_APP_DEBUG_BACKWALL) {
            helpers.forEach((helper) => {
                this.container.addContent(helper);
            })
        }
    }

    protected async loadAndPrepareGeo(id: string, parent?: string): Promise<LoadedGeometry> {
        const geo = this.prepareGeo(id, parent);
        geo.geo = await this.loadGeometry(geo);
        this.addInfoClickHandler(geo.geo);
        this.container.addContent(geo.geo);
        let materialOptions: IJungMaterialOptions[] = [];
        try {
           materialOptions = await this.materialManager.setMaterial(geo.geo, geo);
        } catch (e) {
            console.error(util.format('Error materializing %s', id))
            console.log(e);
        }

        let transformation;
        if (!parent) {
            transformation = this.transformationHandler.setGeometryTransformation(geo.geo, geo.definition.assignment, geo.definition);
        }

        return { geometry: geo, materials: materialOptions, transformation: transformation };
    }

    protected prepareGeo(id: string, parent?: string, frameWall?: boolean): IJungGeometry {
        let definition: IJungGeometryData;
        if (!parent) {
            definition = this.findDefinition(id);
        } else {
            if (!frameWall) {
                definition = this.findChildDefinition(id, parent);
            } else {
                definition = this.findFrameWallDefinition(id, parent);
            }

        }

        return {
            id: definition.name,
            position: {x: 0, y: 0, z: 0},
            definition,
        };
    }

    protected findDefinition(id: string): IJungGeometryData {
        const assignment = this.getAssignment(id);

        return {
            dimensions: {width: 0, depth: 0, height: 0},
            filePath: ('assets/model/' + assignment.data.assetPath).replace('fbx', 'glb'),
            mappedId: id,
            name: id,
            type: this.getGeometryType(assignment.data.geometryType),
            assignment: assignment
        };
    }

    protected findChildDefinition(id: string, parent: string): IJungGeometryData {
        const parentDef = this.findDefinition(parent);
        const childData = parentDef.assignment.data.child;
        if (!childData || childData.productArticleNumber !== id) {
            throw new Error(util.format('Unable to find child definition for %s in %s', id, parent));
        }

        return {
            dimensions: {width: 0, depth: 0, height: 0},
            filePath: ('assets/model/' + childData.assetPath).replace('fbx', 'glb'),
            mappedId: id,
            name: id,
            type: this.getGeometryType(childData.geometryType),
            assignment: {
                productArticleNumber: id,
                data: childData
            }
        };
    }

    protected findFrameWallDefinition(id: string, frameId: string): IJungGeometryData {
        const frameDef = this.findDefinition(frameId);
        const wallData = frameDef.assignment.data.frameWall;
        if (!wallData || wallData.productArticleNumber !== id) {
            throw new Error(util.format('Unable to find child definition for %s in %s', id, frameId));
        }

        return {
            dimensions: {width: 0, depth: 0, height: 0},
            filePath: ('assets/model/' + wallData.assetPath).replace('fbx', 'glb'),
            mappedId: id,
            name: id,
            type: this.getGeometryType(wallData.geometryType),
            assignment: {
                productArticleNumber: id,
                data: wallData
            }
        };
    }

    protected fitCameraToContainer(cameraSettingsName: string) {
        const sceneContent = this.container.getViewerContent();
        const settings = this.options.fitViewFactor[cameraSettingsName] as IViewSettings;
        const cam = this.camera.camera;
        const boxSize = (new MeshUtils()).createBoundingBox(sceneContent.frame).size;
        if (this.viewerSize) {
            const horizontalRatio = this.viewerSize.width / this.viewerSize.height;
            const verticalRatio = this.viewerSize.height / this.viewerSize.width;
            const combiVertRatio = boxSize.y / boxSize.x
            const combiHorizontalRatio = boxSize.x / boxSize.y
            const isVerticalView = horizontalRatio < verticalRatio;
            const isVerticalCombination = boxSize.x < boxSize.y;
            const isHorizontalCombination = boxSize.x > boxSize.y;
            //ToDo Update near/far values => far behind config, near in front of config
            let ratio;
            if (isVerticalView) {
                if (isVerticalCombination) {
                    console.log('Setting camera for vertical view & vertical combination')
                    ratio = combiVertRatio + verticalRatio;
                } else if (isHorizontalCombination) {
                    console.log('Setting camera for vertical view & horizontal combination')
                    ratio = combiHorizontalRatio * (verticalRatio + horizontalRatio + combiVertRatio)
                } else {
                    console.log('Setting camera for vertical view & quadratic combination')
                    ratio = combiVertRatio + verticalRatio;
                }
            } else {
                if (isHorizontalCombination) {
                    console.log('Setting camera for vertical view & vertical combination')
                    ratio = combiHorizontalRatio;
                } else if (isVerticalCombination) {
                    console.log('Setting camera for vertical view & horizontal combination')
                    ratio = combiVertRatio + combiHorizontalRatio;
                } else {
                    console.log('Setting camera for vertical view & quadratic combination')
                    ratio =  combiHorizontalRatio;
                }
            }

            let factor = ratio + settings.baseFactor;

            cam.position.set(settings.camera_x, settings.camera_y, settings.camera_z  * factor);

            return;
        }
        console.warn('Missing viewer size! - Could not fit camera view');
    }

    private getAssignment(articleNumber: string): AssetAssignment {
        for (const assignment of this._assignments) {
            if (assignment.productArticleNumber === articleNumber) {
                return assignment;
            }
        }
        throw new Error(util.format('Could not find assignment for %s', articleNumber));
    }

    private getGeometryType(type: string): GeoType {
        switch(type) {
            case 'module':
                return 'TYPE_MODULE'
            case 'frame':
                return 'TYPE_FRAME'
            case 'framePlate':
                return 'TYPE_BACKWALL'

        }
        throw new Error(`Unknown geometry type: ${type}`)
    }

    private addInfoClickHandler(target: THREE.Object3D) {
        if (this.interaction) {
            target.addEventListener('click', (event) => {
                if (event.target) {this.logClickTarget(event.target)}
            });
            this.interaction.add(target);
        }
    }

    private logClickTarget(target: THREE.Object3D) {
        const info = {
            name: target.name,
            position: target.position,
            definition: target.userData,
            geo: target
        }
        console.log(info);
    }
}
