import {
  Annotation,
  BasicSceneContainer,
  IDimensions2D,
  IRenderConfiguration,
  SceneOptions,
  SimpleViewer
} from 'webgl-helpers';
import { AssetAssignment } from '../../../assets/types/programSchema.json';
import Scene from '../scene/Scene';
import { ArCapabilityHandler, DebugViewerStatsHandler } from '../../../stores/ViewerStore/types';
import { BlenderTransformer } from '../transformer/BlenderTransformer';
import { BlenderCreation } from '../../../assets/types/blenderCreation.json';
import * as THREE from 'three';

export default class Viewer extends SimpleViewer<string, string, string, BasicSceneContainer, Scene> {


  constructor(
    options: SceneOptions<string, string, string>,
    annotation: Annotation,
    private blenderTransformer: BlenderTransformer,
    private arCapabilityHandler: ArCapabilityHandler
  ) {
    super(options, annotation);
  }

  private statsCallback: DebugViewerStatsHandler | null = null;

  async init(parent: HTMLElement, dimensions: IDimensions2D, annotationNames: string[], scene: Scene): Promise<void> {
    await super.init(parent, dimensions, annotationNames, scene);
    this.scene.setViewerSize(dimensions);
  }

  async loadConfiguration(assignments: AssetAssignment[]): Promise<void> {
    this.scene.assignments = assignments;
    for (const assignment of assignments) {
      try {
        await this.scene.loadGeo(assignment.productArticleNumber);
      } catch (e) {
        console.log(e);
      }

    }
    this.scene.showScene();

    const arPossible = this.scene.determineGeometryArCapabilities();
    console.info(`AR Capability: ${arPossible}`)
    this.render();
  }

  clear() {
    this.scene.clearScene(false);
  }

  resize(dimensions: IDimensions2D) {
    super.resize(dimensions);
    this.scene.setViewerSize(dimensions);
    this.render();
  }

  public toggleFrame(): void {
    this.scene.toggleFrame();
    this.render();
  }

  public toggleModules(): void {
    this.scene.toggleModules();
    this.render();
  }

  public toggleCutWall(): void {
    this.scene.toggleCutWall();
    this.render();
  }

  public toggleBackWall(): void {
    this.scene.toggleBackWall();
    this.render();
  }

  public increaseBackPosition(): void {
    const vector = new THREE.Vector3(0, 0, 0.0005)
    this.scene.updateBackWallPosition(vector);
    this.render();
  }

  public decreaseBackWallPosition(): void {
    const vector = new THREE.Vector3(0, 0, -0.0005)
    this.scene.updateBackWallPosition(vector);
    this.render();
  }

  setStatsCallback(callback: DebugViewerStatsHandler): void {
    this.statsCallback = callback;
  }

  async renderCurrentAnimationImages(renderConfig: IRenderConfiguration): Promise<string[]> {
    // @todo implement
    return [];
  }

  async createBlenderCreationJson(): Promise<BlenderCreation> {
    const blender = this.blenderTransformer.transformSceneToBlender(this.scene.loadedGeometries);
    console.log(JSON.stringify(blender));
    return blender;
  }

  destroy(): void {
    this.scene.clearScene();
    this.parent?.removeChild(this.canvas);
  }

  render(): void {
    if (this.camera.update) {
      this.camera.update();
    }
    this.scene.updateAnimation();
    this.renderer.renderer.render(this.scene.scene, this.camera.camera);
    if (this.scene.shadowLightHelper) {
      this.scene.shadowLightHelper.update();
    }
    if (this.statsCallback) {
      this.statsCallback({
        geometries: this.renderer.renderer.info.memory.geometries,
        textures: this.renderer.renderer.info.memory.textures
      });
    }
    this.arCapabilityHandler(this.scene.determineGeometryArCapabilities());
    this.dispatchRenderLoopEvent();
  }

  async getScreenshot(): Promise<string> {

    return new Promise<string>((resolve) => {
      const bgCanvasCtx = document.createElement('canvas').getContext('2d');
      if (!bgCanvasCtx) {

        throw new Error(`ERROR: Could not create canvas.`);
      }
      bgCanvasCtx.canvas.width = this.dimensions.width;
      bgCanvasCtx.canvas.height = this.dimensions.height;
      const renderImg = new Image();
      renderImg.src = this.renderer.renderer.domElement.toDataURL();
      renderImg.style.width = this.dimensions.width + 'px';
      renderImg.style.height = 'auto';
      renderImg.onload = () => {
        bgCanvasCtx.drawImage(renderImg, 0, 0, this.dimensions.width, this.dimensions.height);

        setTimeout(() => {
          resolve(bgCanvasCtx.canvas.toDataURL('image/png'));
        }, 5000);
      };
    });
  }
}
