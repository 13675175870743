import React from 'react';
import styles from './ArPopupContent.module.scss';
import { useLocalization } from '../../stores/LocalizationStore/LocalizationProvider';
import QRFeedback from './QRFeedback';

export interface IProps {
  status: 'generating' | 'generated' | 'failed';
  url: string | null,
}

const ArPopupContent: React.FC<IProps> = ({status, url}) => {
  const t = useLocalization();

  return (
    <div className={styles.arPopupContent}>
      <div className={styles.popupContent}>
        <div className={styles.popupContentInner}>
          <div className={styles.popupContentTitle}>
            {t('ar_title')}
          </div>
          <div className={styles.arPopupContainer}>
            <QRFeedback status={status} link={url} />
            <div className={styles.arPopupServerInfo}>{t('ar_usage')}</div>
          </div>
        </div>
      </div>
    </div>);
};

export default ArPopupContent;
