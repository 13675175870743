import { MaterialUpdater} from "webgl-helpers";
import * as THREE from "three";
import {IJungMaterialOptions} from "../types";
import {degToRad} from "three/src/math/MathUtils";

export class JungMatUpdater extends MaterialUpdater<string> {


    public updateMaterial(target: THREE.Object3D, options: IJungMaterialOptions) {
        const materialObjects = this.finder.findAllMeshesWithMaterial(target, options.area);
        if (!materialObjects || materialObjects.length === 0) {
            console.error(`ERROR: Object with name ${target.name} has no material aera ${options.area}.`);
            return;

        }
        const materialType = this.getMaterialType(materialObjects);

        if (options.material.type !== materialType) {
            const msg = `${target.name}/${options.area} - ERROR: library material (${options.material.type}) and target material(area) (${materialType}) should have same type.`;

            console.warn(msg)
        }
        materialObjects.forEach((obj) => {
            if (options.depthMask) {
                obj.renderOrder = -1
            }

            if ('set' === options.mode) {
                obj.material = options.material;
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj.material.copy(options.material);
            }
            if (options.definition.uv) {
                const origRotation = this.applyUVRotation(obj, options);
                let position = new THREE.Vector3(target.position.x, target.position.y, target.position.z * -1);
                if (options.definition.uv.originX && options.definition.uv.originY && options.definition.uv.originZ) {
                    position = new THREE.Vector3(options.definition.uv.originX, options.definition.uv.originY, options.definition.uv.originZ);
                }
                const gismo = { position, length: options.definition.uv.size };
                this.mapper.map(obj as THREE.Mesh, gismo);
                // Reset rotation after uv mapping
                obj.rotation.order = origRotation.order
                obj.rotation.set(degToRad(origRotation.x), degToRad(origRotation.y), degToRad(origRotation.z))
            }

            this.addAo(options, obj);
            this.addTexture(options, obj);
            this.setTextureColor(options, obj);
            this.setMaterialColor(options, obj);
            //this.addEmissiveTexture(options, obj);
        });
    }

    protected setMaterialColor(options: IJungMaterialOptions, obj: THREE.Mesh) {
        if (!options.color) {
            return;
        }
        const color = options.color;
        const material = obj.material as THREE.MeshStandardMaterial;
        material.color = new THREE.Color(color.hex);
        material.color.setStyle(color.hex);
        material.color.convertSRGBToLinear();
    }

    protected setTextureColor(options: IJungMaterialOptions, obj: THREE.Mesh) {
       if (options.textureColor) {
           const colorValue = parseInt(options.textureColor, 16);
           const material = obj.material as THREE.MeshStandardMaterial;
           material.color = new THREE.Color(colorValue);
       }
    }

    protected addEmissiveTexture(options: IJungMaterialOptions, obj: THREE.Mesh) {
        if (options.emissive) {
            const material = obj.material as THREE.MeshStandardMaterial;
            material.emissiveMap = options.emissive.texture
            material.emissiveIntensity = options.emissive.intensity
            material.emissive = new THREE.Color(Number(options.emissive.color));
            material.needsUpdate = true;
        }
    }

    protected addTexture(options: IJungMaterialOptions, obj: THREE.Mesh) {
        if (options.texture) {
            const material = obj.material as THREE.MeshStandardMaterial
            material.map = options.texture;
            material.emissiveMap = null
            material.emissiveIntensity = 0;
            material.needsUpdate = true;
        }
    }
}