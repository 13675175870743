import React from 'react';
import styles from './ActionButton.module.scss';

interface IProps {
  children: React.ReactNode,

  [index: string]: unknown
}

const ActionButton: React.FC<IProps> = React.forwardRef<HTMLDivElement, IProps>(({ children }, ref) => {

  return (
    <div ref={ref} className={styles.buttonWrapper}>
      {children}
    </div>
  );
});

export default ActionButton;
