import React from 'react';
import Icon from '../../Icon/Icon';
import IconButton from '../IconButton';
import { useLocalization } from '../../../stores/LocalizationStore/LocalizationProvider';

interface IProps {
  onClick: () => void,
}

const ExpandMenuButton: React.FC<IProps> = ({ onClick }) => {

  const t = useLocalization();

  return (
    <IconButton onClick={onClick} title={t('show_menus')}>
      <Icon size={'large'} type={'arrowBack'} />
    </IconButton>
  );
};

export default ExpandMenuButton;
