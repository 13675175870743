export const DEBUG = (process.env.REACT_APP_DEBUG ?? '') === 'on';
if (DEBUG) {
  console.warn('Debug mode is enabled. Debug mode should be disabled in production environments. Set the env ' +
      'variable \'REACT_APP_DEBUG\' to \'off\' to disable the debug mode.'
  );
  console.info("%c Press key 'p' to download screenshots of all combinations.", 'font-weight: bold; color: green');
} else {
  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}
}
