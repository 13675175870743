import React from 'react';
import styles from './DebugViewer.module.scss';

interface IProps {
  children: React.ReactNode,
}

const DebugViewer: React.FC<IProps> = ({children}) => {

  return (
    <div className={styles.debugViewer}>
      {children}
    </div>
  );
};

export default DebugViewer;
