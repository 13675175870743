export const downloadBlob = (function () {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = 'none';

  return function (blob: Blob, fileName: string) {
    // @ts-ignore
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    // @ts-ignore
    window.URL.revokeObjectURL(url);
  };
}());

export const openImageInNewTab = (url: string) => {
  const image = new Image();
  image.src = url;

  const w = window.open("");
  if(w) {
    w.document.write(image.outerHTML);
  }
}

export const fileToDataUrl = (file: File) => new Promise<string>((resolve, reject) => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader.result as string);
  };
  reader.onerror = (error) => {
    reject(error);
  };
});

export const arrayBufferToDataUrl = (buffer: ArrayBuffer) => new Promise<string>((resolve, reject) => {
  const blob = new Blob([buffer],{type:'application/octet-binary'});
  const reader = new FileReader();
  reader.onload = ()=> {
    if(typeof reader.result === 'string') {
      resolve(reader.result as string);
    }
    else {
      reject('Result is not a string');
    }
  };
  reader.onerror = (error) => {
    reject(error);
  };
  reader.readAsDataURL(blob);
})

export const dataUrlToFile = (dataUrl: string, fileName: string) => {
  const arr = dataUrl.split(',');
  if(arr.length < 2) {

    throw new Error('Could not convert to file.')
  }

  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, {type:mime});
}

export const dataURLToBlob = (dataURI: string): Blob => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //New Code
  return new Blob([ab], { type: mimeString });
}
