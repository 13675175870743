import React, { useEffect, useRef } from 'react';
import { Id, Value } from './types';
import styles from './SelectInput.module.scss';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

const ANIMATION_DURATION = 200

interface IProps {
  current: Id
  values: Value[],
  expanded: boolean,
  onExpand: (expanded: boolean) => void,
  onSelect: (id: Id) => void,
}

const noop = () => {}

const SelectInput: React.FC<IProps> = ({ values = [], onSelect, current,
                                         expanded, onExpand
}) => {
  const refList = useRef<HTMLUListElement>(null);
  const refListItem = useRef<HTMLLIElement>(null);
  const curr = values.find((val: Value) => val.id === current);
  const currLabel = curr?.label;
  const currUrl = curr?.iconUrl;

  const clickHandler = (id: Id) => {
    onExpand(false);
    onSelect(id);
  };

  useEffect(() => {
    if(expanded && refList.current && refListItem.current ) {
      const scrollHeightItem = refListItem.current.offsetTop
      refList.current.scrollTo(0, scrollHeightItem - 5) //5px padding from top for shadowed gap
    }
  }, [expanded]);

  const iconStyles = expanded ? { transform: 'rotate(180deg)' } : {};

  const listClasses = classNames(
    styles.list,
    expanded ? styles.visible : styles.hidden
  );

  const listStyles = {
    transition: `all ease-in-out ${ANIMATION_DURATION}ms`,
  };

  return (
    <div className={styles.menu}>
      <div className={classNames(styles.header, styles.rowItem, expanded ? styles.headerOpen : styles.headerClosed)}
           onClick={() => onExpand(!expanded)}>
        <div className={styles.imageWrapper}>
          <img src={currUrl} title={currLabel} alt={currLabel} />
        </div>
        <div className={classNames(styles.label, styles.headerLabel)}>
          <div dangerouslySetInnerHTML={{ __html: currLabel ?? '' }} />
          <div className={styles.arrowIcon}>
            <Icon type={'arrow'} style={iconStyles} />
          </div>
        </div>
      </div>
        <ul ref={refList} className={listClasses}  style={listStyles}>
          {values.map((v, index) => (
            <li
              key={index}
              ref={v === curr ? refListItem : undefined}
              className={classNames(styles.rowItem, styles.listItem, v === curr && styles.selected)}
              onClick={v === curr ? noop : () => clickHandler(v.id)}
            >
              <div className={styles.imageWrapper}>
                <img src={v.iconUrl} title={v.label} alt={v.label} />
              </div>
              <div className={styles.label}>
                <div dangerouslySetInnerHTML={{ __html: v.label }} />
              </div>
            </li>
          ))}
        </ul>
    </div>);
};

export default SelectInput;
