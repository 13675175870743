import * as React from 'react';
import styles from './LoadingCurl.module.scss';
import classNames from 'classnames';
import { Size } from './types';

export const sizeToCSSUnit = (size: Size): string => {
  switch (size) {
    case 'xs': return '0.5em';
  }

  return '112px';
};

interface IProps {
  size?: Size;
  [index: string]: any;
}

const LoadingCurl: React.FC<IProps> = ({
                                    size = 'md',
                                    ...props
                                  }) => {
  const classes = classNames(styles.circle, styles[size]);
  const sizeCSS = sizeToCSSUnit(size ?? 'md');

  return (
    <div className={classes} style={{width: sizeCSS, height: sizeCSS}} {...props}>
      <div style={{ width: sizeCSS, height: sizeCSS }} />
      <div style={{ width: sizeCSS, height: sizeCSS }} />
      <div style={{ width: sizeCSS, height: sizeCSS }} />
      <div style={{ width: sizeCSS, height: sizeCSS }} />
    </div>
  );
};

export default LoadingCurl;
